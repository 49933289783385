import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import { useContextState } from 'context/context';
import { getCurrentIndex, getTotalStaked } from 'services/Statistics';
import './_styles.scss';

const TVL: React.FunctionComponent = () => {
  const { tokenPrice } = useContextState();
  const [value, setValue] = useState<string>('0');
  const [totalStaked, setTotalStaked] = useState<string>('0');

  useEffect(() => {
    (async () => {
      setValue(await getCurrentIndex());
      setTotalStaked(await getTotalStaked());
    })();
  }, []);

  return (
    <div className="tvl-box">
      <div>
        <p className="tvl-title">TVL</p>
        <p className="tvl-values">${numeral(Number(+totalStaked * tokenPrice)).format('0,0.00')}</p>
      </div>
      <div>
        <p className="tvl-title">Current Index</p>
        <p className="tvl-values">{`${value} Token`}</p>
      </div>
    </div>
  );
};
export default TVL;
