import React from 'react';
import { Col, Row } from 'antd';
import ContentCard from 'components/global/content-card';
import './_styles.scss';
import { addComma } from 'utils/numbersUtils';

interface IStatisticsItem {
  label: string;
  value: string;
}

interface IStatisticsProps {
  backingPerMonk: number | null;
  currentIndex: string;
  marketCap: number;
  tokenPrice: number;
  lastData: any | null;
}

const Statistics: React.FunctionComponent<IStatisticsProps> = ({
  backingPerMonk,
  currentIndex,
  marketCap,
  tokenPrice,
  lastData,
}) => {
  const row1: IStatisticsItem[] = [
    { label: 'Market Cap', value: `$${addComma(marketCap * tokenPrice, true)}` },
    { label: 'Monk  Price', value: `$${tokenPrice}` },
    { label: 'Zen  Price', value: '--' },
  ];
  const row2: IStatisticsItem[] = [
    {
      label: 'Circulating Supply (Total)',
      value: lastData
        ? `${addComma(lastData.monkCirculatingSupply, true)} / ${addComma(
            lastData.totalSupply,
            true,
          )}`
        : '0 / 0',
    },
    { label: 'Backing per Monk', value: `$${addComma(backingPerMonk || 0)}` },
    { label: 'Current Index', value: `${addComma(Number(currentIndex))} Monk` },
  ];

  return (
    <ContentCard maxWidth={false}>
      <Row className="dashboard-statistics-row">
        {row1.map(item => (
          <Col
            key={`${item.value}-${item.label}`}
            xs={24}
            md={8}
            className="dashboard-statistics-col"
          >
            <p className="statistic-col-label">
              {item.label === 'Zen  Price' && <span>g</span>}
              {item.label}
            </p>
            <p className="statistic-col-value">{item.value}</p>
          </Col>
        ))}
      </Row>
      <Row className="dashboard-statistics-row" style={{ marginBottom: 15 }}>
        {row2.map(item => (
          <Col
            key={`${item.value}-${item.label}`}
            xs={24}
            md={8}
            className="dashboard-statistics-col"
          >
            <p className="statistic-col-label">{item.label}</p>
            <p className="statistic-col-value">{item.value}</p>
          </Col>
        ))}
      </Row>
    </ContentCard>
  );
};
export default Statistics;
