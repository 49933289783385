import React, { useState } from 'react';
import clsx from 'clsx';
import { Modal } from 'antd';
import { useContextDispatch, useContextState } from 'context/context';
import { setConnectedAddress } from 'context/actions';
import Utils from 'libs/Utils';
import { networkChainID } from 'constants/CoinsAddress';
import ContainedButton from 'components/global/buttons/contained-button';
import './_styles.scss';

interface IConnectWalletProps {
  className?: string;
  withoutMarginInlineEnd?: boolean;
  fullWidth?: boolean;
}

const ConnectWallet: React.FunctionComponent<IConnectWalletProps> = ({
  className,
  withoutMarginInlineEnd = false,
  fullWidth = false,
}) => {
  const [showError, setShowError] = useState(false);
  const [netError, setNetError] = useState(false);
  const { connectedAccount } = useContextState();
  const dispatch = useContextDispatch();
  const connectWallet = async () => {
    // @ts-ignore
    const { ethereum } = window;
    if (!ethereum) {
      setShowError(true);
      return;
    }
    // TODO change testnet
    if (parseInt(ethereum.chainId) !== networkChainID.testnet) {
      setNetError(true);
      return;
    }
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    dispatch(setConnectedAddress(accounts[0]));
  };

  if (connectedAccount) {
    return (
      <ContainedButton
        text={Utils.minimizeAddress(connectedAccount)}
        className="yellow-button"
        style={{
          minWidth: 191,
          marginInlineEnd: withoutMarginInlineEnd ? 0 : 16,
          width: fullWidth ? '100%' : undefined,
        }}
      />
    );
  }

  return (
    <>
      <ContainedButton
        text="Connect Wallet"
        className={clsx('yellow-button', className)}
        style={
          className
            ? undefined
            : {
                minWidth: 191,
                marginInlineEnd: withoutMarginInlineEnd ? 0 : 16,
                width: fullWidth ? '100%' : undefined,
              }
        }
        onClick={connectWallet}
      />
      <Modal
        visible={netError || showError}
        footer={null}
        className="no-metamask-modal"
        style={{ maxWidth: 'calc(100% - 8px) !important' }}
        onCancel={netError ? () => setNetError(false) : () => setShowError(false)}
      >
        {netError ? (
          <>
            <p style={{ fontSize: '17px' }}>Please change Metamask network to Ethereum Mainnet!</p>
          </>
        ) : (
          <>
            <p>Please Install MetaMask in Your Browser!</p>
            <a href="https://metamask.io/" target="_blank">
              Download MetaMask
            </a>
          </>
        )}
      </Modal>
    </>
  );
};
export default ConnectWallet;
