import React from 'react';
import './_styles.scss';

const BondHeader: React.FunctionComponent = () => {
  return (
    <>
      <div className="bond-header">
        <span className="bond-header-title">BOND (🙏, 🙏)</span>
      </div>
      <hr className="bond-header-divider" />
    </>
  );
};
export default BondHeader;
