const Utils = {
  minimizeAddress: (address: string): string => {
    return `${address.substring(0, 5)}...${address.substring(address.length - 8, address.length)}`;
  },
  trim: (number = 0, precision = 0): string => {
    const array = Number(number).toFixed(8).split('.');
    if (array.length === 1) return number.toString();
    if (precision === 0) return array[0].toString();

    const poppedNumber = array.pop() || '0';
    array.push(poppedNumber.substring(0, precision));
    return array.join('.');
  },
};

export default Utils;

//
// export const getAPYValue = async (connectedAccount?: string): Promise<string> => {
//   const zenContract = CreateZenContract();
//   const stakingContract = CreateStakingContract();
//   if (zenContract && stakingContract) {
//     const hadStake = connectedAccount && (await zenContract._isN(connectedAccount));
//     const epoch = await stakingContract.epoch();
//     const circ = await zenContract.circulatingSupply();
//     const stakingReward = epoch.distribute;
//     const stakingRebase = new BigNumber(stakingReward.toString()).div(circ.toString()).times(1);
//     const stakingAPY = stakingRebase.plus(1).pow(365 * 3);
//     const trimmedStakingAPY = Utils.trim(stakingAPY.toNumber() * 100, 1);
//     return new Intl.NumberFormat('en-US').format(Number(trimmedStakingAPY));
//   }
//   return '0'; //
// };

// export const getBoostAmount = async (): Promise<string> => {
//   const zenContract = CreateZenContract();
//   const stakingContract = CreateStakingContract();
//   if (zenContract && stakingContract) {
//     const epoch = await stakingContract.epoch();
//     const circ = await zenContract.circulatingSupply();
//     const stakingReward = epoch.distribute;
//     const stakingRebase = Number(stakingReward.toString()) / Number(circ.toString());
//     const stakingAPY = Math.pow(1 + stakingRebase, 365 * 3) - 1;
//     const trimmedStakingAPY = Utils.trim(stakingAPY * 100, 1);
//     return new Intl.NumberFormat('en-US').format(Number(trimmedStakingAPY));
//   }
//   return '0';
// };
