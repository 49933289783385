// export type ContractType =
//   | 'auth'
//   | 'ZEN'
//   | 'treasury'
//   | 'sZEN'
//   | 'gZen'
//   | 'staking'
//   | 'distributor'
//   | 'bondDepository'
//   | 'bondTeller'
//   | 'calculator'
//   | 'ico'
//   | 'dai'
//   | 'monk'
//   | 'ptoken';

// TODO Change Contract Address
const ContractAddress = {
  ZEN_ADDRESS: '0x8108611a8183ca603DDF7AA81D71035B52CC6ca4',
  MONK_ADDRESS: '0xF43f66429458ba0fB2bfDF4F74A1Aa37a206fD5C',
  DAI_ADDRESS: '0xf4CF1f1C104f7CabE8d2b263656051F91F646Bc7',
  TREASURY_ADDRESS: '0x8019344E5E02874c655cD3AaCA5d8A63a50d6257',
  MONK_BONDING_CALC_ADDRESS: '',
  STAKING_ADDRESS: '0xaA9221a15541F0c097E895CEEc8C526950C7c888',
  STAKING_HELPER_ADDRESS: '0x22479B1433799fE898c34b6FF61A6CC549AE4B73',
  STAKING_WARMUP_ADDRESS: '0x89f9B7da9B80e908B93d775e91F27ede202E9AD5',
  STAKING_DISTRIBUTER: '0xe47FDc6D2e79D9839FEb01DD09ffA67B2213d9C6',
  DAIBONDING_ADDRESS: '0xACBD1a1ec329C37a82103451923D967174F1aD95',
  NEW_DAIBONDING_ADDRESS: '0xACBD1a1ec329C37a82103451923D967174F1aD95', // TODO Change this Address
  DAIMONKBONDING_ADDRESS: '',
  NFT_ADDRESS: '0x95CC5ED1130265f50F1059cb8ba2c3c971a2feE8',
  LP_ADDR: '',
  GZEN_ADDRESS: '0x92D4ADB783e751f5Bb93FE2290C59D8A55A8FE8f',
  CLAIM_ADDRESS: '0x5E807aB723DA3469602d4b97aabbe26Ab5992B14',
};

// const ContractAddress = {
//   ZEN_ADDRESS: '0x1A44e142715725316803314623DD626acE065eab',
//   MONK_ADDRESS: '0xB1B7Bd1f0f576Ff5E8e6DA0f5A817A3E51b3a85C',
//   DAI_ADDRESS: '0x2382A0826f52c0E664F6a4254c675aB9DBa35a23',
//   TREASURY_ADDRESS: '0x43191bd2a5bdCC6700a0aE5c63da9df54F2f4DB4',
//   MONK_BONDING_CALC_ADDRESS: '0xCA352b60963Eb556C9EBc22472c84ca39d27dD9A',
//   STAKING_ADDRESS: '0xBc33ad9D32a29A05cBd3f6a63864d35fd1A08E25',
//   STAKING_HELPER_ADDRESS: '0x129E9CCD24815Fb5a16C1663e321b9694D4Cbb80',
//   STAKING_WARMUP_ADDRESS: '0x4b3E990159480c7f86B4adFe62Ccc2CAd8bC3B87',
//   STAKING_DISTRIBUTER: '0x09D414321cb45daC0642e2C898B9B9bc4F14192B',
//   DAIBONDING_ADDRESS: '0x50b0BaBEA192aa93d8251cE2106Aad045DF9253e',
//   DAIMONKBONDING_ADDRESS: '0xC04efAd6ed39a27B70bcB55Cc1ca075DcFeb194d',
//   NFT_ADDRESS: '0x67DB58C3622B1FBd2126AD9965f0383625A0fd08',
//   LP_ADDR: '0x99f84e21F5Cf8cC20fEbBde5B444CF96FeBa23E9',
// };

// const ContractAddress: Record<ContractType, string> = {
//   auth: '0x40E926FD89b0b2432e7635E5a1984bE8E35C3875',
//   ZEN: '0xCe3479875d8fE31716105A2E8ABd026e64D8D97C',
//   sZEN: '0x4aB31b781102946436BF83d733f9673C89dd2506',
//   gZen: '0xa56c1bB2841068cA27171ab402c371Bc3c719fD5',
//   treasury: '0xb5B632cea67b1C6E5a75d82B91b6c4a3800cc39d',
//   staking: '0x40b0b2D0A75F7eF1A56697fcC35C6A858201dB7e',
//   bondDepository: '0xF379D08B943Ff876d88Ce707dd5230DAa30C3d4D',
//   bondTeller: '0xefd1EF080026e8e9e1146DEc2157760fDeD319Ec',
//   calculator: '0x098e1eBaeA2F63f491d1edDAf158697B3D4FeF8E',
//   distributor: '0xb67558f1277CeFa5425F35E7A85df12452413a92',
//   ico: '0xBaf660B4B3E6A62dd9D8F1784ebbFf0493a6CE2E',
//   monk: '0xa4E27ea37D18bb0f483779f9E75A6024EFa5E73e',
//   ptoken: '0x568bCf0E010d737f95d594650d8c6Df14e5973Ad',
//   dai: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
// };
export default ContractAddress;

export const MAX_PRICE = 60000;
