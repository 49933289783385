import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import Navbar from 'components/layouts/navbar';
import SideBar from 'components/layouts/side-bar';
import './_styles.scss';

interface IUseCaseLayoutProps {
  activeKey: string;
  className?: string;
  style?: CSSProperties;
  maxWidth?: boolean;
  withBack?: boolean;
}

const UseCaseLayout: React.FunctionComponent<IUseCaseLayoutProps> = ({
  activeKey,
  children,
  className,
  style,
  maxWidth = true,
  withBack,
}) => {
  return (
    <div className="use-case-layout">
      <Navbar withBack={withBack} />
      <SideBar activeKey={activeKey} />
      <div
        className={clsx('use-case-child', className, {
          'use-case-max-width': maxWidth,
        })}
        style={style}
      >
        {children}
      </div>
    </div>
  );
};
export default UseCaseLayout;
