import React from 'react';
import Circle from '../circle';

interface ITooltipValueOutputProps {
  value: string;
  color: string;
}

const TooltipValueOutput: React.FunctionComponent<ITooltipValueOutputProps> = ({
  color,
  value,
}) => {
  return (
    <div
      style={{
        padding: 0,
        margin: 0,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Circle color={color} />
      <p style={{ padding: 0, margin: 0, marginLeft: 10 }}>{value}</p>
    </div>
  );
};

export default TooltipValueOutput;
