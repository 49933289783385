import React, { useEffect } from 'react';
import { notification as antNotification } from 'antd';
import { useContextState } from 'context/context';

const Notification: React.FunctionComponent = () => {
  const { notification } = useContextState();
  useEffect(() => {
    if (notification) {
      antNotification.open({
        type: notification.type,
        message: notification.title,
        description: notification.description,
        onClick: notification.onClick,
        placement: 'topLeft',
        duration: 30 * 1000,
        style: { background: '#262626', color: '#FFFFFF' },
      });
    }
  }, [notification]);

  return null;
};
export default Notification;
