import { ICalculatorForm } from 'pages/calculator';
import React from 'react';
import './_styles.scss';
import Utils from 'libs/Utils';

interface IProps {
  value: ICalculatorForm;
}

const CalculatorMain: React.FunctionComponent<IProps> = ({ value }) => {
  const APY = Intl.NumberFormat('en-US').format(Number(Utils.trim(value.currentApy, 2)));
  return (
    <div className="calculator-main">
      <div className="calculator-apy-box">
        <p className="apy-box-title">Current APY</p>
        <p className="calculator-apy-number">{APY} %</p>
      </div>
      <div className="calculator-tvl-box">
        <div>
          <p className="calculator-tvl-title">Token PRICE</p>
          <p className="calculator-tvl-values">${value.monkPrice}</p>
        </div>
        <div>
          <p className="calculator-tvl-title">YOUR Token BALANCE</p>
          <p className="calculator-tvl-values">{Number(value.monkBalance).toFixed(4)} Token </p>
        </div>
      </div>
    </div>
  );
};
export default CalculatorMain;
