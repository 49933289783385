import React from 'react';
import './_styles.scss';

export interface IContentRowProps {
  label: string;
  value: string;
}

const ContentRow: React.FunctionComponent<IContentRowProps> = ({ label, value }) => {
  return (
    <div className="content-row">
      <span className="content-row-title">{label}</span>
      <span>{value}</span>
    </div>
  );
};
export default ContentRow;
