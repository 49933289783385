import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Spin, Table } from 'antd';
import ContainedButton from 'components/global/buttons/contained-button';
import IconCreator from 'components/global/icon-creator';
import { IBondData } from 'models/Bond';
import { getBondData, getPublicBondData } from 'services/BondServices';
import { useContextState } from 'context/context';
import MobileBox from './_MobileBox';
import './_styles.scss';
import ConnectWallet from 'components/global/connect-wallet';
import clsx from 'clsx';

interface IBondTableProps {
  onOpen: (bond: IBondData) => void;
  isSoldOut: boolean;
}

const BondTable: React.FunctionComponent<IBondTableProps> = ({ onOpen, isSoldOut }) => {
  const [loading, setLoading] = useState(true);
  const [bonds, setBonds] = useState<IBondData[]>([]);
  const { connectedAccount, tokenPrice } = useContextState();
  const showTable = useMediaQuery({
    query: '(min-width: 600px)',
  });

  useEffect(() => {
    (async () => {
      setBonds([]);
      setLoading(true);
      const results: IBondData[] = [];
      // const daiBond = connectedAccount
      //   ? await getBondData({
      //       type: 'dai',
      //       userAddress: connectedAccount,
      //     })
      //   : await getPublicBondData({
      //       type: 'dai',
      //     });
      const newDaiBond = connectedAccount
        ? await getBondData({
            type: 'new-dai',
            userAddress: connectedAccount,
          })
        : await getPublicBondData({
            type: 'new-dai',
          });
      const daiMonkBond = connectedAccount
        ? await getBondData({
            type: 'daiMonk',
            userAddress: connectedAccount,
          })
        : await getPublicBondData({
            type: 'daiMonk',
          });
      setLoading(false);
      // if (daiBond) {
      //   results.push(daiBond);
      // }
      if (newDaiBond) {
        results.push(newDaiBond);
      }
      if (daiMonkBond) {
        results.push(daiMonkBond);
      }
      setBonds(results);
    })();
  }, [connectedAccount]);

  const tableColumns = [
    {
      title: <p className="bond-table-title">Mint</p>,
      dataIndex: 'mint',
    },
    {
      title: <p className="bond-table-title">Price</p>,
      dataIndex: 'price',
    },
    {
      title: <p className="bond-table-title">ROI</p>,
      dataIndex: 'roi',
    },
    {
      title: <p className="bond-table-title">Purchased</p>,
      dataIndex: 'purchased',
    },
    {
      dataIndex: 'action',
    },
  ];

  // const tableDataSource = [tableRow, tableRow, tableRow];
  const tableDataSource = bonds.map(bond => ({
    key: bond.coin,
    mint: (
      <div
        key={`${bond.bondPrice}-${bond.ROI}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <IconCreator name={bond.coin} />
        <p
          className="bond-table-values"
          style={{ textTransform: 'uppercase', minWidth: 84, textDecoration: 'none' }}
        >
          {bond.coin === 'new-dai' ? 'DAI' : bond.coin === 'daiMonk' ? 'DAI-MONK LP' : bond.coin}
        </p>
        {bond.coin === 'daiMonk' && (
          <a
            href="https://app.sushi.com/add/0x6b175474e89094c44da98b954eedeac495271d0f/0xa4E27ea37D18bb0f483779f9E75A6024EFa5E73e"
            target="_blank"
            style={{ marginBottom: 16 }}
          >
            <img src="/images/open-in-new.svg" alt="open in new" />
          </a>
        )}
      </div>
    ),
    price: (
      <p
        className={clsx('bond-table-values', {
          'bond-table-values__disable': bond.coin === 'dai',
          'line-through': isSoldOut,
        })}
      >
        {Number(bond.bondPrice).toFixed(4)}
      </p>
    ),
    roi: (
      <p
        className={clsx('bond-table-values', {
          'bond-table-values__disable': bond.coin === 'dai',
          'line-through': isSoldOut,
        })}
      >
        {bond.ROI(tokenPrice)}
      </p>
    ),
    purchased: (
      <p
        className={clsx('bond-table-values', {
          'bond-table-values__disable': bond.coin === 'dai',
          'line-through': isSoldOut,
        })}
      >
        ${bond.purchased(tokenPrice)}
      </p>
    ),
    action: connectedAccount ? (
      <ContainedButton
        text={isSoldOut ? 'Sold Out!' : 'Mint'}
        style={{ minWidth: 87 }}
        onClick={() => onOpen(bond)}
      />
    ) : (
      <ConnectWallet />
    ),
  }));

  return (
    <div className="bond-table">
      {loading ? (
        <div className="bond-table-loading">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {showTable ? (
            <Table columns={tableColumns} dataSource={tableDataSource} pagination={false} />
          ) : (
            <>
              {bonds.map(bond => (
                <MobileBox
                  bond={bond}
                  onClick={() => onOpen(bond)}
                  connectedAddress={connectedAccount}
                />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};
export default BondTable;
