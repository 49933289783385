import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconCreator from 'components/global/icon-creator';
import ContainedButton from 'components/global/buttons/contained-button';
import './_styles.scss';
import OutlinedButton from 'components/global/buttons/outlined-button';
import { useMediaQuery } from 'react-responsive';

const FirstView: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const notSmallScreen = useMediaQuery({
    query: '(min-width: 450px)',
  });

  return (
    <>
      <div className="logo">
        <IconCreator name="logo" />
        {notSmallScreen && (
          <ContainedButton
            text="Enter App"
            style={{ marginInlineEnd: 32 }}
            onClick={() => navigate('/dashboard')}
          />
        )}
      </div>
      <div className="header">
        <IconCreator name="hero" className="hero" />
        <div className="title-container">
          <p className="header-title">The First DAO </p>
          <p className="header-title">For The People</p>
          <p className="header-desc">
            Monastery DAO is building the strongest community-operated DAO protocol to ensure
            passive, sustainable yields while pushing blockchain innovations forward.
          </p>
          <div className="header-buttons">
            <ContainedButton
              text="Enter App"
              style={{ marginInlineEnd: 16 }}
              onClick={() => navigate('/dashboard')}
            />
            <OutlinedButton
              target="_blank"
              className="documentation-button"
              text="Documentation"
              href="https://docs.monasteryfinance.info/"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default FirstView;
