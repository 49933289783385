import React from 'react';
import TextInput from 'components/global/text-input';
import ContainedButton from 'components/global/buttons/contained-button';

interface IFormProps {
  handleForm: () => Promise<void>;
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  buttonText: string;
  onMax: () => void;
}

const InputRow: React.FunctionComponent<IFormProps> = ({
  handleForm,
  amount,
  setAmount,
  buttonText,
  onMax,
}) => {
  return (
    <div className="input-row">
      <TextInput
        hasMaxAction
        onMax={onMax}
        value={amount}
        className="input-row-input"
        onChanged={value => setAmount(value)}
        type="number"
      />
      <ContainedButton text={buttonText} onClick={handleForm} className="stake-button" />
    </div>
  );
};

export default InputRow;
