import React, { CSSProperties } from 'react';
import { Button, ButtonProps } from 'antd';
import clsx from 'clsx';
import './_styles.scss';

interface IProps extends ButtonProps {
  text: string | React.ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

const MSContainedButton: React.FunctionComponent<IProps> = ({
  text,
  className,
  style,
  onClick,
  ...props
}) => {
  return (
    <Button
      type="primary"
      className={clsx('ms-contained-button', className)}
      style={style}
      onClick={onClick}
      {...props}
    >
      {text}
    </Button>
  );
};
export default MSContainedButton;
