import React from 'react';

interface IDollarOutputProps {
  value: string;
}

const DollarOutput: React.FunctionComponent<IDollarOutputProps> = ({ value }) => {
  return <p style={{ padding: 0, margin: 0, marginLeft: 30, textAlign: 'right' }}>${value}</p>;
};

export default DollarOutput;
