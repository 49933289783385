import React from 'react';
import ContentRow, { IContentRowProps } from 'components/global/content-row';
import { IUserBalance } from 'models/@types';

interface IProps {
  userBalance: IUserBalance;
}

const Balance: React.FunctionComponent<IProps> = ({ userBalance }) => {
  const contentRows: IContentRowProps[] = [
    { label: 'Your balance', value: `${userBalance.monkToken.toFixed(4)} MONK` },
    { label: 'Your Staked Balance', value: `${userBalance.zenToken.toFixed(4)} ZEN` },
    // { label: 'Wrapped Balance', value: `${userBalance.gZenToken} Token` },
    // { label: 'Exchange Balance', value: `${userBalance.exchangeBalance} Token` },
    { label: 'Next Reward Yield', value: userBalance.nextRewardYield },
    { label: 'ROI (5-Day Rate)', value: userBalance.ROI },
  ];

  return (
    <div className="tab-one-text-content">
      {contentRows.map(item => (
        <ContentRow key={item.label} label={item.label} value={item.value} />
      ))}
    </div>
  );
};

export default Balance;
