import { CreateZenContract, CreateStakingContract, CreateMonkContract } from 'services/Contracts';
import { IUserBalance } from 'models/@types';
import Utils from 'libs/Utils';
import BigNumber from 'bignumber.js';
import { BASE_TOKEN_DECIMALS } from 'constants/CoinsAddress';

export const GetUserBalance = async (address: string): Promise<IUserBalance | null> => {
  const monkContract = CreateMonkContract();
  const zenContract = CreateZenContract();
  const stakingContract = CreateStakingContract();
  if (zenContract && monkContract && stakingContract) {
    const balance = await monkContract.balanceOf(address);
    const stakedBalance = await zenContract.balanceOf(address);
    const hadStake = address && (await zenContract._isN(address));
    const epoch = await stakingContract.epoch();
    const circ = await zenContract.circulatingSupply();
    const stakingReward = epoch.distribute;
    const stakingRebase = new BigNumber(stakingReward.toString())
      .div(circ.toString())
      .times(hadStake ? 1 : 0.9)
      .toNumber();
    const fiveDayRate = Math.pow(1 + stakingRebase, 5 * 3) - 1;
    const ROIValue = Utils.trim(Number(fiveDayRate) * 100, 4);

    const nextRewardYieldValue = Utils.trim(stakingRebase * 100, 4);
    const nextRewardValue = Utils.trim((Number(nextRewardYieldValue) / 100) * stakedBalance, 4);

    BigNumber.config({ DECIMAL_PLACES: 9 });

    return {
      // monkToken: parseInt(balance) * Math.pow(10, -9),
      monkToken: new BigNumber(balance.toString())
        .div(new BigNumber(10).pow(BASE_TOKEN_DECIMALS))
        .toNumber(),
      zenToken: new BigNumber(stakedBalance.toString())
        .div(new BigNumber(10).pow(BASE_TOKEN_DECIMALS))
        .toNumber(),
      ROI: `${ROIValue} %`,
      nextRewardYield: `${nextRewardYieldValue} %`,
      exchangeBalance: nextRewardValue,
    };
  }
  return null;
};

export const GetMonkBalance = async (address: string): Promise<number> => {
  const monkContract = CreateMonkContract();
  if (monkContract) {
    const balance = await monkContract.balanceOf(address);
    return parseInt(balance) * Math.pow(10, -9);
  }
  return 0;
};
