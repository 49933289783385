import React, { useEffect, useState } from 'react';
import './_styles.scss';
import { getAPYValue } from 'services/Statistics';
import { useContextState } from 'context/context';

const APY: React.FunctionComponent = () => {
  const { connectedAccount } = useContextState();

  const [value, setValue] = useState<string>('0');

  useEffect(() => {
    (async () => {
      const res = await getAPYValue(connectedAccount);
      setValue(res);
    })();
  }, [connectedAccount]);

  return (
    <div className="apy-box">
      <p className="apy-title">APY</p>
      <p className="apy-number">{`${value} %`}</p>
    </div>
  );
};
export default APY;
