import React from 'react';
import { Spin } from 'antd';
import { useContextState } from 'context/context';
import './_styles.scss';

const AppLoading: React.FunctionComponent = () => {
  const { appLoading } = useContextState();
  if (!appLoading) {
    return null;
  }
  return (
    <div className="app-loading">
      <Spin size="large" />
    </div>
  );
};
export default AppLoading;
