import React, { useState } from 'react';
import { Modal, Tabs, Row, Col } from 'antd';
import TextInput from 'components/global/text-input';
import ContainedButton from 'components/global/buttons/contained-button';
import ContentRow from 'components/global/content-row';
import { IBondData } from 'models/Bond';
import Utils from 'libs/Utils';
import { useContextDispatch, useContextState } from 'context/context';
import './_styles.scss';
import { onBondService, onRedeemService } from 'services/BondServices';
import { setLoading, setNotification } from 'context/actions';
import BigNumber from 'bignumber.js';
import { BASE_TOKEN_DECIMALS } from 'constants/CoinsAddress';
// import { callBondContract, callClaim } from 'services/BondServices';
// import Checkbox from 'antd/lib/checkbox/Checkbox';

interface IBondDialogModalProps {
  open: boolean;
  onClose: () => void;
  bond: IBondData | null;
  isSoldOut: boolean;
}

const BondDialogModal: React.FunctionComponent<IBondDialogModalProps> = ({
  open,
  onClose,
  bond,
  isSoldOut,
}) => {
  const { connectedAccount, tokenPrice } = useContextState();
  const dispatch = useContextDispatch();
  const { TabPane } = Tabs;
  const [quantity, setQuantity] = useState('');

  // useEffect(() => {
  //   (async () => {
  //     console.log('component: ', connectedAccount, bond);
  //     if (connectedAccount && bond) {
  //       await getRewards(connectedAccount, bond.index);
  //       await getTimeToVest(connectedAccount, bond.index);
  //     }
  //   })();
  // }, [connectedAccount, bond]);

  if (!bond || !connectedAccount) {
    return null;
  }

  const bondHandler = async () => {
    if (quantity === '') {
      return;
    }
    dispatch(setLoading(true));
    const response = await onBondService({
      address: connectedAccount,
      amount: +quantity,
      type: bond.coin,
    });
    dispatch(setLoading(false));
    if (response) {
      dispatch(
        setNotification({
          type: response.success ? 'success' : 'error',
          title: response.success ? 'SUCCESS' : 'ERROR',
          description: response.message,
        }),
      );
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
    // await callBondContract(quantity, bond.address, bond.index, bond.bondPriceBigNumber);
  };

  const onInputChanged = (value: string) => {
    if (+value >= 0) {
      setQuantity(value);
    }
  };

  const onRedeem = async (stake: boolean) => {
    dispatch(setLoading(true));
    const response = await onRedeemService({
      stake,
      type: bond.coin,
      address: connectedAccount,
    });
    dispatch(setLoading(false));
    if (response) {
      dispatch(
        setNotification({
          type: response.success ? 'success' : 'error',
          title: response.success ? 'SUCCESS' : 'ERROR',
          description: response.message,
        }),
      );
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  const InputRow = (
    <div className="input-row">
      <TextInput
        value={quantity}
        hasMaxAction
        className="input-row-input"
        onChange={e => onInputChanged(e.target.value)}
        onMax={() => setQuantity(bond ? bond.yourBalance : '0')}
        type="number"
        readOnly={bond.coin === 'dai' || isSoldOut}
        min={0}
      />
      <ContainedButton
        text={bond.coin === 'dai' || isSoldOut ? 'SOLD OUT' : 'BOND'}
        className="stake-button"
        disabled={bond.coin === 'dai' || isSoldOut}
        onClick={bondHandler}
      />
    </div>
  );

  const youWillGet =
    bond.coin === 'daiMonk' && bond.valuePerOneLp && bond.lpBondPrice
      ? Utils.trim(
          new BigNumber(((Number(quantity) * bond.valuePerOneLp) / bond.lpBondPrice) * 100)
            .div(new BigNumber(10).pow(BASE_TOKEN_DECIMALS))
            .toNumber(),
          4,
        ) || '0'
      : Utils.trim(Number(quantity) / bond.bondPrice, 4) || '0';

  return (
    <Modal
      destroyOnClose
      title={
        <>
          <span
            style={{
              background: '#181818',
              borderRadius: '100%',
              padding: 4,
            }}
          >
            🙏
          </span>
          &nbsp; Monk
        </>
      }
      visible={open}
      // onOk={handleOk}
      footer={null}
      maskStyle={{
        // background: "#999",
        boxShadow:
          '0px 7px 12px rgba(0, 0, 0, 0.0392157), 0px 13px 14px rgba(0, 0, 0, 0.0784314), 0px 20px 15px rgba(0, 0, 0, 0.0392157), 0px 2px 4px rgba(0, 0, 0, 0.501961)',
        borderRadius: 15,
        backdropFilter: 'blur(4px)',
      }}
      width="85%"
      centered
      // wrapClassName="bond-modal"
      onCancel={() => {
        setQuantity('');
        onClose();
      }}
    >
      <section className="bond-tvl-box">
        <div style={{ marginBottom: '16px' }}>
          <p className="bond-tvl-title">Mint Price</p>
          <p className="bond-tvl-values">${bond.mintPrice}</p>
        </div>
        <div style={{ marginBottom: '16px' }}>
          <p className="bond-tvl-title">Token PRICE</p>
          <p className="bond-tvl-values">${tokenPrice}</p>
        </div>
      </section>
      <hr style={{ height: '2px', border: 0, background: '#1f1f1f', margin: '0 -24px' }} />
      <section className="tabs-section">
        <Tabs defaultActiveKey={/*bond.coin !== 'dai' ? '1' : '2'*/ '2'}>
          <TabPane tab="MINT" key="1">
            {/*<Row>*/}
            {/*  <Col md={24} className="ant-checkbox-wrapper">*/}
            {/*    <Checkbox>Use Token </Checkbox>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {InputRow}
            <p className="note-text">
              Note: The "Approve" transaction is only needed when minting for the first time;
              subsequent minting only requires you to perform the "Mint" transaction.
            </p>
            <div className="tab-one-text-content">
              <ContentRow label="Your balance" value={Number(bond.yourBalance).toFixed(4)} />
              <ContentRow label="You Will Get" value={`${youWillGet} Monk`} />
              <ContentRow label="Max You Can Buy" value={Number(bond.maxYouCanBuy).toFixed(4)} />
              <ContentRow label="ROI" value={bond.ROI(tokenPrice)} />
              <ContentRow label="Vesting Term" value={bond.vestingTerm} />
              <ContentRow label="Minimum purchase" value="0.01 Monk" />
            </div>
          </TabPane>
          <TabPane tab="Redeem" key="2">
            {/*<ContainedButton*/}
            {/*  className="bond-redeem-button"*/}
            {/*  text="Claim"*/}
            {/*  onClick={() => onRedeem(false)}*/}
            {/*  style={{*/}
            {/*    maxWidth: '400px',*/}
            {/*    margin: '0 auto',*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center',*/}
            {/*    justifyContent: 'center',*/}
            {/*  }}*/}
            {/*/>*/}
            <Row gutter={20}>
              <Col xs={24} md={12}>
                <ContainedButton
                  className="bond-redeem-button"
                  text="Claim"
                  onClick={() => onRedeem(false)}
                />
              </Col>
              <Col xs={24} md={12}>
                <ContainedButton
                  className="bond-redeem-button"
                  text="Claim and Auto Stake"
                  onClick={() => onRedeem(true)}
                />
              </Col>
            </Row>
            <div className="tab-one-text-content">
              <div className="content-row">
                <div>Pending Rewards</div>
                <div className="value-section">{bond.pendingReward} Monk</div>
              </div>
              <div className="content-row">
                <div>Claimable Rewards</div>
                <div className="value-section">{bond.claimableReward} Monk</div>
              </div>
              <div className="content-row">
                <div>Time until fully vested</div>
                <div className="value-section">{bond.timeUntilFullyVested}</div>
              </div>
              <div className="content-row">
                <div>ROI</div>
                <div className="value-section">{bond.redeemROI(tokenPrice)}</div>
              </div>
              <div className="content-row">
                <div>Vesting Term</div>
                <div className="value-section">{bond.vestingTerm}</div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </section>
    </Modal>
  );
};
export default BondDialogModal;
