import React from 'react';
import { Row, Col, Slider } from 'antd';
import TextInput from 'components/global/text-input';
import './_styles.scss';
import { ICalculatorForm } from 'pages/calculator';

interface IProps {
  setFormValue: React.Dispatch<React.SetStateAction<ICalculatorForm>>;
  formValue: ICalculatorForm;
}

const CalculatorForm: React.FunctionComponent<IProps> = ({ setFormValue, formValue }) => {
  const handleInputChange = async (value: number, type: keyof ICalculatorForm) => {
    if (value < 0) return;
    setFormValue(prev => ({ ...prev, [type]: value }));
  };

  return (
    <Row gutter={[32, 32]} className="calculator-form">
      <Col xs={24} md={12} className="input-column-wrapper">
        <TextInput
          value={formValue.tokenAmount}
          onChanged={val => handleInputChange(+val, 'tokenAmount')}
          label="Token Amount"
          hasMaxAction
          onMax={() =>
            setFormValue(prevState => ({ ...prevState, tokenAmount: formValue.monkBalance }))
          }
          endText="MAX"
          type="number"
          min={0}
        />
      </Col>
      <Col xs={24} md={12} className="input-column-wrapper">
        <TextInput
          value={formValue.apy}
          onChanged={val => handleInputChange(+val, 'apy')}
          label="APY (%)"
          hasMaxAction
          actionText="CURRENT"
          onMax={() => setFormValue(prevState => ({ ...prevState, apy: formValue.currentApy }))}
          type="number"
          min={0}
        />
      </Col>
      <Col xs={24} md={12} className="input-column-wrapper">
        <TextInput
          value={formValue.tokenPrice}
          onChanged={val => handleInputChange(+val, 'tokenPrice')}
          label="Token price at purchase ($)"
          hasMaxAction
          actionText="CURRENT"
          onMax={() =>
            setFormValue(prevState => ({ ...prevState, tokenPrice: formValue.monkPrice }))
          }
          type="number"
          min={0}
        />
      </Col>
      <Col xs={24} md={12} className="input-column-wrapper">
        <TextInput
          value={formValue.futureTokenPrice}
          onChanged={val => handleInputChange(+val, 'futureTokenPrice')}
          label="Future Token market price ($)"
          hasMaxAction
          actionText="CURRENT"
          onMax={() =>
            setFormValue(prevState => ({ ...prevState, futureTokenPrice: formValue.monkPrice }))
          }
          type="number"
          min={0}
        />
      </Col>
      <Col xs={24}>
        <div className="range-slider-box">
          <p>
            Staking duration: <b>{formValue.days} days</b>
          </p>
          <Slider
            value={formValue?.days}
            onAfterChange={val => handleInputChange(val, 'days')}
            onChange={val => handleInputChange(val, 'days')}
            min={0}
            max={365}
            trackStyle={{
              width: '100%',
              background: '#E6F300',
              borderRadius: '10px',
            }}
            handleStyle={{
              background: '#E6F300',
              borderRadius: '10px',
            }}
            style={{ width: '100%' }}
          />
        </div>
      </Col>
    </Row>
  );
};
export default CalculatorForm;
