import React from 'react';
import './_styles.scss';

const StakeMain: React.FunctionComponent = () => {
  return (
    <div className="claim-main">
      <div className="apy-box">
        <p className="apy-title">Claim Reward</p>
        <p className="apy-number">100</p>
      </div>
      <div className="tvl-box">
        <div>
          <p className="tvl-title">Stake Time</p>
          <p className="tvl-values">1 Day</p>
        </div>
        <div>
          <p className="tvl-title">stake reward per day</p>
          <p className="tvl-values">1</p>
        </div>
      </div>
    </div>
  );
};
export default StakeMain;
