import React from 'react';
import './_styles.scss';

const CalculatorHeader: React.FunctionComponent = () => {
  return (
    <>
      <div className="calculator-title-wrapper">
        <span className="calculator-title">Calculator</span>
        <p className="calculator-subtitle">Estimate your returns</p>
      </div>
      <hr className="calculator-header-divider" />
    </>
  );
};
export default CalculatorHeader;
