import React from 'react';
import './_styles.scss';
import IconCreator from 'components/global/icon-creator';
import ContainedButton from 'components/global/buttons/contained-button';

const HowItWorks: React.FunctionComponent = () => {
  return (
    <section className="how-it-works-section">
      <p className="how-it-works-title">HOW MONASTERY.FINANCE WORKS</p>
      <p className="how-it-works-subtitle">
        We’re building a trusted, community-based DAO protocol where the protocol owns its own
        liquidity. Learn more about <b>Monastery.finance Elite,</b> tokens, bonding & governance
        today!
      </p>
      <div className="how-works-row">
        <div className="how-works-text-container how-works-first">
          <span className="counter-text">01</span>
          <div className="how-works-text-box">
            <p className="how-works-text-title">BOND & LP FEES</p>
            <p className="how-works-text-subtitle">
              Bonding Proceeds & LP fee’s help increase The Monasteries Treasury and safely secure
              the liquidity to help control the MONK supply.
            </p>
          </div>
        </div>
        <div className="how-works-second">
          <IconCreator name="how-it-works-1" className="how-works-img" />
        </div>
      </div>
      <div className="how-works-row">
        <div className="how-works-first">
          <IconCreator name="how-it-works-2" className="how-works-img" />
        </div>
        <div className="how-works-text-container how-works-second">
          <span className="counter-text">02</span>
          <div className="how-works-text-box">
            <p className="how-works-text-title">MONASTERY TREASURY</p>
            <p className="how-works-text-subtitle">
              Monastery Treasury proceeds are used to help obtain more funds for the Treasury
              Balance & allows us to back the available <b>MONK</b> tokens & moderate the staking
              APY %.
            </p>
          </div>
        </div>
      </div>
      <div className="how-works-row">
        <div className="how-works-text-container how-works-first">
          <span className="counter-text">03</span>
          <div className="how-works-text-box">
            <p className="how-works-text-title">
              <b>MONK</b> TOKEN
            </p>
            <p className="how-works-text-subtitle">
              Bonding & LP fee’s is the main driver for Treasury growth. The MONK token is the
              native token to the Monastery. Learn more about our Tokens.
            </p>
          </div>
        </div>
        <div className="how-works-second">
          <IconCreator name="how-it-works-3" className="how-works-img" />
        </div>
      </div>
      <ContainedButton
        style={{ width: '100%', maxWidth: 450 }}
        text="Documentation"
        target="_blank"
        className="documentation-button"
        href="https://docs.monasteryfinance.info/"
      />
    </section>
  );
};
export default HowItWorks;
