import React from 'react';
import ContentCard from 'components/global/content-card';
import TooltipValueOutput from 'components/global/outputs/TooltipValueOutput';
import DollarOutput from 'components/global/outputs/DollarOutput';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  AreaChart,
  CartesianGrid,
  Area,
  Tooltip,
} from 'recharts';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import moment from 'moment';
import { addComma } from 'utils/numbersUtils';
import { getDates } from 'pages/dashboard/data';

interface ChartProps {
  data: any[];
}

const MarketValue: React.FunctionComponent<ChartProps> = ({ data }) => {
  const chartData: any[] = [];
  data.forEach(item => {
    const time = moment(item.timestamp * 1000).format('MMM DD');
    chartData.unshift({
      date: time,
      DAI: item.treasuryDaiMarketValue,
    });
  });

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            border: 'none',
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            padding: 15,
          }}
        >
          {payload.map(item => (
            <div
              key={item.dataKey}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: 5,
              }}
            >
              <TooltipValueOutput color="green" value={item.dataKey?.toString() || '--'} />
              <DollarOutput value={addComma(Number(item.value), true)} />
            </div>
          ))}
          <p style={{ padding: 0, margin: 0, marginTop: 10 }}>{label}</p>
        </div>
      );
    }

    return null;
  };

  const headerValue = addComma(Number(data[0].treasuryMarketValue), true);
  const days = getDates(data);

  return (
    <ContentCard title="Market Value of Treasury Assets" maxWidth={false}>
      <p className="chart-title-value">${headerValue} Today</p>
      <ResponsiveContainer width="100%" height={350}>
        <AreaChart data={chartData} margin={{ top: 10, right: 5, left: -5, bottom: 0 }}>
          <defs>
            <linearGradient id="DAI" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="green" stopOpacity={0.8} />
              <stop offset="95%" stopColor="green" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            style={{ stroke: '#ffffff' }}
            tickLine={false}
            strokeWidth={2}
            tick={{
              strokeWidth: 1,
              fontWeight: 100,
            }}
            ticks={days}
          />
          <YAxis
            style={{ stroke: '#ffffff', fontWeight: 100 }}
            axisLine={false}
            tickLine={false}
            tickFormatter={(value, index) => (index !== 0 ? `$${value / 1000000}M` : value)}
          />
          <CartesianGrid strokeDasharray="2" vertical={false} style={{ opacity: 0.2 }} />
          <Tooltip content={<CustomTooltip />} />
          <Area type="natural" dataKey="DAI" stroke="none" fillOpacity={1} fill="url(#DAI)" />
        </AreaChart>
      </ResponsiveContainer>
    </ContentCard>
  );
};

export default MarketValue;
