import React, { useState } from 'react';
import { Row, Col, Tabs } from 'antd';
import { useContextDispatch } from 'context/context';
import { claimYieldedNFTService, stakeNFTService, unStakeNFTService } from 'services/ClaimService';
import ContainedButton from 'components/global/buttons/contained-button';
import { setLoading, setNotification } from 'context/actions';
import './_styles.scss';
import InputRow from './components/input-row';
import Balance from '../balance';
import StakeNftDialogModal from './components/dialog-modal';

const { TabPane } = Tabs;

const ClaimTabs: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useContextDispatch();
  const [index, setIndex] = useState('');

  const stakeToken = async () => {
    if (index !== '') {
      dispatch(setLoading(true));
      const response = await stakeNFTService(index);
      dispatch(setLoading(false));
      if (response) {
        dispatch(
          setNotification({
            type: response.success ? 'success' : 'error',
            title: response.success ? 'SUCCESS' : 'ERROR',
            description: response.message,
          }),
        );
        if (response.success) {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      }
    }
  };

  const unStakeToken = async () => {
    if (index !== '') {
      dispatch(setLoading(true));
      const response = await unStakeNFTService(index);
      dispatch(setLoading(false));
      if (response) {
        dispatch(
          setNotification({
            type: response.success ? 'success' : 'error',
            title: response.success ? 'SUCCESS' : 'ERROR',
            description: response.message,
          }),
        );
        if (response.success) {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      }
    }
  };

  const onClaimYielded = async () => {
    dispatch(setLoading(true));
    const response = await claimYieldedNFTService();
    if (response) {
      dispatch(
        setNotification({
          type: response.success ? 'success' : 'error',
          title: response.success ? 'SUCCESS' : 'ERROR',
          description: response.message,
        }),
      );
      if (response.success) {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    }
    dispatch(setLoading(false));
    // logic
  };

  return (
    <>
      <Tabs defaultActiveKey="1" onChange={() => setIndex('')} className="claim-nft">
        <TabPane tab="Stake Nft" key="1">
          <InputRow
            amount={index}
            buttonText="STAKE"
            handleForm={stakeToken}
            setAmount={setIndex}
          />
          <Balance />
        </TabPane>
        <TabPane tab="claim" key="2" style={{ margin: '0 16px' }}>
          <Row className="boost-box">
            <Col xs={24} md={12} className="boost-box-button" style={{ justifyContent: 'center' }}>
              <ContainedButton
                text="Claim gZen by NFT"
                className="stake-monk-button"
                onClick={() => setOpen(true)}
              />
            </Col>
            <Col xs={24} md={12} className="boost-box-button" style={{ justifyContent: 'center' }}>
              <ContainedButton
                text="Claim yielded NFT"
                className="stake-monk-button"
                onClick={onClaimYielded}
              />
            </Col>
          </Row>
          <Balance />
          <StakeNftDialogModal open={open} onClose={() => setOpen(false)} />
        </TabPane>
        <TabPane tab="unStake Nft" key="3">
          <InputRow
            amount={index}
            buttonText="UNSTAKE"
            handleForm={unStakeToken}
            setAmount={setIndex}
          />
          <Balance />
        </TabPane>
      </Tabs>
    </>
  );
};
export default ClaimTabs;
