import React, { useEffect, useState } from 'react';
import { timeToNextRebase } from 'services/StakingServices';
import './_styles.scss';

const StakeHeader: React.FunctionComponent = () => {
  const [value, setValue] = useState<string>('Calculating...');

  useEffect(() => {
    (async () => {
      const res = await timeToNextRebase();
      setValue(res);
    })();
  }, []);
  return (
    <>
      <div className="stake-header">
        <p className="stake-title">Single Stake (🙏, 🙏)</p>
        <p className="stake-subtitle">{value}</p>
      </div>
      <hr className="stake-header-divider" />
    </>
  );
};
export default StakeHeader;
