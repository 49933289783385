import React from 'react';
import UseCaseLayout from 'components/layouts/use-case';
import './_styles.scss';
// import { Col, Row } from 'antd';
// import Details from './components/details';
// import Information from './components/information';

const GovernanceIdPage: React.FunctionComponent = () => {
  return (
    <UseCaseLayout activeKey="5" className="governance-id-container" withBack>
      <p className="dashboard-coming-soon">COMING SOON</p>
      {/*<Row gutter={32}>*/}
      {/*  <Col xs={24} md={16}>*/}
      {/*    <Details />*/}
      {/*  </Col>*/}
      {/*  <Col xs={24} md={8}>*/}
      {/*    <Information />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </UseCaseLayout>
  );
};
export default GovernanceIdPage;
