import React from 'react';

interface IPercentageOutputProps {
  value: string;
}

const PercentageOutput: React.FunctionComponent<IPercentageOutputProps> = ({ value }) => {
  return <p style={{ padding: 0, margin: 0, marginLeft: 30, textAlign: 'right' }}>{value}%</p>;
};

export default PercentageOutput;
