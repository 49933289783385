import React from 'react';
import ContentCard from 'components/global/content-card';
import TooltipValueOutput from 'components/global/outputs/TooltipValueOutput';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  AreaChart,
  CartesianGrid,
  Area,
  Tooltip,
} from 'recharts';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { addComma } from 'utils/numbersUtils';
import moment from 'moment';
import { getDates } from 'pages/dashboard/data';

interface ChartProps {
  data: any[];
}

const TotalValue: React.FunctionComponent<ChartProps> = ({ data }) => {
  const chartData: any[] = [];
  data.forEach(item => {
    const time = moment(item.timestamp * 1000).format('MMM DD');
    chartData.unshift({
      date: time,
      totalAmount: Number(item.totalValueLocked).toFixed(4),
    });
  });

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            border: 'none',
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            padding: 15,
          }}
        >
          <TooltipValueOutput color="#b7c919" value="Total Value Deposited" />
          <p style={{ padding: 0, margin: 0, marginTop: 10 }}>
            {addComma(Number(payload[0].value) || 0, true)}
          </p>
          <p style={{ padding: 0, margin: 0, marginTop: 10 }}>{label}</p>
        </div>
      );
    }

    return null;
  };
  const tvlStr = addComma(Number(data[0].totalValueLocked), true);
  const days = getDates(data);
  return (
    <ContentCard title="Total Value Deposited" maxWidth={false}>
      <p className="chart-title-value">${tvlStr} Today</p>
      <ResponsiveContainer width="100%" height={350}>
        <AreaChart data={chartData} margin={{ top: 10, right: 0, left: -10, bottom: 0 }}>
          <defs>
            <linearGradient id="totalAmount" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#b7c919" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#b7c919" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            style={{ stroke: '#ffffff' }}
            tickLine={false}
            strokeWidth={2}
            tick={{
              strokeWidth: 1,
              fontWeight: 100,
            }}
            ticks={days}
          />
          <YAxis
            style={{ stroke: '#ffffff', fontWeight: 100 }}
            axisLine={false}
            tickLine={false}
            tickFormatter={(value, index) => (index !== 0 ? `$${value / 1000000}M` : value)}
          />
          <CartesianGrid strokeDasharray="2" vertical={false} style={{ opacity: 0.2 }} />
          <Tooltip content={<CustomTooltip />} />

          <Area
            type="monotone"
            dataKey="totalAmount"
            stroke="none"
            fillOpacity={1}
            fill="url(#totalAmount)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </ContentCard>
  );
};
export default TotalValue;
