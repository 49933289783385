import {
  CreateStakingContract,
  CreateMonkContract,
  CreateStakingHelperContract,
  CreateNftContract,
  CreateZenContract,
} from './Contracts';
import ContractAddress from 'constants/ContractAddress';
import { BASE_TOKEN_DECIMALS } from 'constants/CoinsAddress';
import { IResponse } from 'models/@types';
import moment from 'moment';
import BigNumber from 'bignumber.js';

export const ApproveToken = async (amount: string): Promise<boolean> => {
  const monkContract = CreateMonkContract();
  if (monkContract) {
    // const approved = await monkContract.approve(ContractAddress.STAKING_HELPER_ADDRESS, +amount);
    const approved = await monkContract.approve(
      ContractAddress.STAKING_HELPER_ADDRESS,
      new BigNumber(2).pow(256).minus(1).toString(),
    );
    await approved.wait();
    return !!approved.hash;
  }
  return false;
};

export const NftApproveToken = async (tokenId: number): Promise<boolean> => {
  const nftContract = CreateNftContract();
  if (!nftContract) return false;

  const approved = await nftContract.getApproved(tokenId);
  if (approved.toLowerCase() === ContractAddress.STAKING_ADDRESS.toLowerCase()) return true;

  try {
    const approve = await nftContract.approve(ContractAddress.STAKING_ADDRESS, tokenId);
    await approve.wait();
    return !!approve;
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return false;
  }
};

export const AllowanceToken = async (address: string): Promise<number> => {
  const monkContract = CreateMonkContract();
  if (monkContract) {
    const approved = await monkContract.allowance(address, ContractAddress.MONK_ADDRESS);
    return parseInt(approved);
  }
  return 0;
};

export const stakingTransaction = async (amount: number): Promise<IResponse | undefined> => {
  const stakingContract = CreateStakingHelperContract();
  const decimalAmount = new BigNumber(amount).times(new BigNumber(10).pow(BASE_TOKEN_DECIMALS));
  try {
    if (stakingContract) {
      const approved = await ApproveToken(decimalAmount.toString());
      if (approved) {
        const response = await stakingContract.stake(decimalAmount.toString());
        await response.wait();
        if (!!response) {
          return {
            success: true,
            message: 'Your transaction successfully executed.',
          };
        }
        return {
          success: false,
          message: 'An error occurred',
        };
      }
    }
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return {
      success: false,
      message: errorMessage,
    };
  }
};

export const unStakingTransaction = async (amount: number): Promise<IResponse | undefined> => {
  const stakingContract = CreateStakingContract();
  const zenContract = CreateZenContract();
  const decimalAmount = new BigNumber(amount).times(new BigNumber(10).pow(BASE_TOKEN_DECIMALS));
  try {
    if (stakingContract && zenContract) {
      // const approved = await zenContract.approve(
      //   ContractAddress.STAKING_ADDRESS,
      //   decimalAmount.toNumber(),
      // );
      const approved = await zenContract.approve(
        ContractAddress.STAKING_ADDRESS,
        new BigNumber(2).pow(256).minus(1).toString(),
      );
      await approved.wait();
      if (approved) {
        const response = await stakingContract.unstake(decimalAmount.toString(), true);
        await response.wait();
        if (!!response) {
          return {
            success: true,
            message: 'Your transaction successfully executed.',
          };
        }
      }
      return {
        success: false,
        message: 'An error occurred',
      };
    }
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return {
      success: false,
      message: errorMessage,
    };
  }
};

export const boostStakingTransaction = async (tokenId: number): Promise<IResponse | undefined> => {
  const stakingContract = CreateStakingContract();
  try {
    if (stakingContract) {
      const approved = await NftApproveToken(tokenId);
      if (approved) {
        const response = await stakingContract.boost(tokenId);
        await response.wait();
        if (!!response) {
          return {
            success: true,
            message: 'Your transaction successfully executed.',
          };
        }
        return {
          success: false,
          message: 'An error occurred',
        };
      }
    }
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return {
      success: false,
      message: errorMessage,
    };
  }
};

export const unBoostStakingTransaction = async (): Promise<IResponse | undefined> => {
  const stakingContract = CreateStakingContract();
  try {
    if (stakingContract) {
      const response = await stakingContract.unboost();
      await response.wait();
      if (!!response) {
        return {
          success: true,
          message: 'Your transaction successfully executed.',
        };
      }
      return {
        success: false,
        message: 'An error occurred',
      };
    }
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return {
      success: false,
      message: errorMessage,
    };
  }
};

export const hadTokenBoosting = async (connectedAccount: string): Promise<boolean> => {
  const zenContract = CreateZenContract();
  try {
    if (zenContract) {
      return (await zenContract._isN(connectedAccount)) as boolean;
    }
    return false;
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return false;
  }
};

export const timeToNextRebase = async (): Promise<string> => {
  const stakingContract = CreateStakingContract();
  try {
    if (stakingContract) {
      const epoch = await stakingContract.epoch();
      const diff = moment.unix(epoch.endTime.toString()).diff(moment());
      if (diff < 0) {
        return 'Rebasing...';
      }
      return `${moment.duration(diff, 'milliseconds').humanize()} to next rebase`;
    }
    return 'Unable to calculate';
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return 'Unable to calculate';
  }
};
