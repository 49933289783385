import React, { useEffect, useState } from 'react';
import ContentRow, { IContentRowProps } from 'components/global/content-row';
import { getClaimData, getGZenBalance, IClaimData } from 'services/ClaimService';
import { useContextState } from 'context/context';
import { addComma } from 'utils/numbersUtils';

const Balance: React.FunctionComponent = () => {
  const { connectedAccount } = useContextState();
  const [gzenBalance, setGZenBalance] = useState('0');
  const [state, setState] = useState<IClaimData>({
    payout: 0,
    nftNumber: 0,
  });
  const contentRows: IContentRowProps[] = [
    { label: 'gZen Balance', value: gzenBalance },
    { label: 'Claimable', value: `${state.payout.toFixed(4)}` },
    { label: 'Staked NFTs', value: `${state.nftNumber.toFixed(4)}` },
    { label: 'NFT balance', value: '0' },
  ];

  useEffect(() => {
    (async () => {
      const response = await getClaimData();
      if (response) {
        setState(response);
      }
      if (connectedAccount) {
        const balance = await getGZenBalance(connectedAccount);
        if (balance) {
          setGZenBalance(addComma(balance));
        }
      }
    })();
  }, []);

  return (
    <div className="tab-one-text-content">
      {contentRows.map(item => (
        <ContentRow key={item.label} label={item.label} value={item.value} />
      ))}
    </div>
  );
};

export default Balance;
