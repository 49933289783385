import React from 'react';
import './_styles.scss';
import ContainedButton from 'components/global/buttons/contained-button';
import IconCreator from 'components/global/icon-creator';
import { useNavigate } from 'react-router-dom';

const Intro: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <section className="intro-section">
      <div className="intro-section-desc">
        <p className="intro-main-text">Monastery Finance Elite</p>
        <p className="intro-sub-text">
          Our goal is to bring protocol owned liquidity operated by the entire participating
          community. Learn about the Monastery Finance, tokens, bonding & governance today!
        </p>
        <div className="intro-buttons-container">
          <ContainedButton
            text="Enter App"
            style={{ width: '100%' }}
            onClick={() => navigate('/dashboard')}
          />
          <ContainedButton
            target="_blank"
            className="documentation-button"
            style={{ width: '100%' }}
            text="Documentation"
            href="https://docs.monasteryfinance.info/"
          />
        </div>
      </div>
      <IconCreator name="illustration-2" className="intro-section-img" />
    </section>
  );
};
export default Intro;
