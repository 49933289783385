import React from 'react';
import useTitle from 'hooks/useTitle';
import UseCaseLayout from 'components/layouts/use-case';
// import GovernanceForm from './components/governance-form';

const GovernanceCreatePage: React.FunctionComponent = () => {
  useTitle({ title: 'Create Proposal' });
  return (
    <UseCaseLayout activeKey="5" withBack className="governance-create-container">
      <p className="dashboard-coming-soon">COMING SOON</p>
      {/*<GovernanceForm />*/}
    </UseCaseLayout>
  );
};
export default GovernanceCreatePage;
