import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconCreator from 'components/global/icon-creator';
import ContainedButton from 'components/global/buttons/contained-button';
import './_styles.scss';
import { useContextState } from 'context/context';
import { getAPYValue } from 'services/Statistics';

const Stake: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { connectedAccount } = useContextState();

  const [value, setValue] = useState<string>('0');

  useEffect(() => {
    (async () => {
      if (!connectedAccount) return;
      const res = await getAPYValue(connectedAccount);
      setValue(res);
    })();
  }, [connectedAccount]);

  return (
    <section className="stake-section">
      <IconCreator name="staking-right" className="staking-right-img" />
      <IconCreator name="staking-left" className="staking-left-img" />
      <div className="stake-container">
        <div className="stake-section-left">
          <p className="stake-number">{value} %</p>
          <p className="stake-number-sub-text">MONK STAKING APY</p>
          <ContainedButton text="STAKE NOW" onClick={() => navigate('/stake')} />
        </div>
        <div className="stake-section-right">
          <p className="stake-title">Sustainable Staking Returns</p>
          <p className="stake-sub-title">
            Our Treasury Revenue Is Design To Outperform Staking Rewards
          </p>
          <p className="stake-text">
            The Monastery was designed off the back of OHM with many additions to help improve
            security and longevity of the protocol. All tokens minted that are used for staking
            rewards are backed with the Monasteries Reserve from the Treasury.
          </p>
        </div>
      </div>
    </section>
  );
};
export default Stake;
