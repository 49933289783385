import React from 'react';

const LogoDark: React.FunctionComponent = () => {
  return (
    <svg
      width="269"
      height="47"
      viewBox="0 0 269 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 1.5625L2.75627 6.2085H61.49L64.2463 1.5625H0Z" fill="#E6F300" />
      <path d="M7.50439 14.0168L10.2607 18.6628H53.9852L56.7415 14.0168H7.50439Z" fill="#E6F300" />
      <path d="M13.8149 19.5229V41.0119H18.4609V19.7931L13.8149 19.5229Z" fill="#E6F300" />
      <path d="M45.7852 41.0119H50.4312V21.6525L45.7852 21.3823V41.0119Z" fill="#E6F300" />
      <path d="M13.8149 7.0686V18.6628H18.4609V7.33879L13.8149 7.0686Z" fill="#E6F300" />
      <path d="M45.7852 18.663H50.4312V9.19829L45.7852 8.9281V18.663Z" fill="#E6F300" />
      <path
        d="M30.8178 44.1199C42.3385 44.1199 53.1573 44.9624 62.5388 46.4365C59.6611 42.4396 47.0708 39.4259 31.9673 39.4259C17.3578 39.4259 5.0937 42.2445 1.70752 46.048C10.4735 44.8153 20.3523 44.1199 30.8178 44.1199Z"
        fill="#E6F300"
      />
      <path
        d="M97.2099 18.1904L97.161 9.11162L92.7562 16.5019H90.6027L86.2224 9.35633V18.1904H81.7441V1.06061H85.7329L91.7528 10.947L97.6259 1.06061H101.615L101.664 18.1904H97.2099Z"
        fill="#0E0E0E"
      />
      <path
        d="M116.614 18.533C114.803 18.533 113.172 18.1496 111.72 17.3829C110.284 16.6161 109.15 15.5557 108.318 14.2016C107.503 12.8476 107.095 11.3222 107.095 9.62552C107.095 7.92885 107.503 6.40348 108.318 5.04941C109.15 3.69534 110.284 2.63492 111.72 1.86816C113.172 1.1014 114.803 0.718018 116.614 0.718018C118.425 0.718018 120.048 1.1014 121.484 1.86816C122.936 2.63492 124.07 3.69534 124.885 5.04941C125.717 6.40348 126.133 7.92885 126.133 9.62552C126.133 11.3222 125.717 12.8476 124.885 14.2016C124.07 15.5557 122.936 16.6161 121.484 17.3829C120.048 18.1496 118.425 18.533 116.614 18.533ZM116.614 14.5197C117.479 14.5197 118.262 14.3158 118.963 13.908C119.665 13.5001 120.219 12.9291 120.627 12.195C121.035 11.4445 121.239 10.588 121.239 9.62552C121.239 8.66298 121.035 7.81465 120.627 7.08052C120.219 6.33007 119.665 5.75092 118.963 5.34306C118.262 4.93521 117.479 4.73129 116.614 4.73129C115.749 4.73129 114.966 4.93521 114.265 5.34306C113.563 5.75092 113.009 6.33007 112.601 7.08052C112.193 7.81465 111.989 8.66298 111.989 9.62552C111.989 10.588 112.193 11.4445 112.601 12.195C113.009 12.9291 113.563 13.5001 114.265 13.908C114.966 14.3158 115.749 14.5197 116.614 14.5197Z"
        fill="#0E0E0E"
      />
      <path
        d="M147.853 1.06061V18.1904H143.864L136.302 9.06268V18.1904H131.555V1.06061H135.544L143.105 10.1884V1.06061H147.853Z"
        fill="#0E0E0E"
      />
      <path
        d="M165.631 14.8623H158.387L157.042 18.1904H152.098L159.66 1.06061H164.432L172.018 18.1904H166.977L165.631 14.8623ZM164.212 11.2896L162.009 5.80802L159.807 11.2896H164.212Z"
        fill="#0E0E0E"
      />
      <path
        d="M182.295 18.533C180.908 18.533 179.562 18.3617 178.257 18.0191C176.952 17.6765 175.892 17.2197 175.076 16.6487L176.667 13.0759C177.433 13.5817 178.323 13.9895 179.334 14.2995C180.346 14.6095 181.341 14.7645 182.32 14.7645C184.179 14.7645 185.109 14.2995 185.109 13.3696C185.109 12.8802 184.84 12.5213 184.302 12.2929C183.78 12.0482 182.931 11.7953 181.757 11.5343C180.468 11.2569 179.391 10.9633 178.526 10.6533C177.662 10.327 176.92 9.81313 176.3 9.11162C175.68 8.41012 175.37 7.4639 175.37 6.27297C175.37 5.22887 175.655 4.29081 176.226 3.45879C176.797 2.61045 177.646 1.94158 178.771 1.45215C179.913 0.962729 181.308 0.718018 182.956 0.718018C184.081 0.718018 185.191 0.848531 186.284 1.10956C187.377 1.35427 188.339 1.72133 189.171 2.21076L187.679 5.80802C186.047 4.92706 184.465 4.48658 182.931 4.48658C181.969 4.48658 181.267 4.6334 180.827 4.92706C180.386 5.2044 180.166 5.57146 180.166 6.02826C180.166 6.48505 180.427 6.82765 180.949 7.05605C181.471 7.28444 182.311 7.521 183.47 7.76571C184.775 8.04305 185.852 8.34486 186.7 8.67114C187.565 8.98111 188.307 9.48685 188.927 10.1884C189.563 10.8735 189.881 11.8116 189.881 13.0025C189.881 14.0303 189.596 14.9602 189.025 15.7922C188.454 16.6243 187.597 17.2931 186.455 17.7989C185.313 18.2883 183.926 18.533 182.295 18.533Z"
        fill="#0E0E0E"
      />
      <path
        d="M198.41 4.90258H193.149V1.06061H208.492V4.90258H203.255V18.1904H198.41V4.90258Z"
        fill="#0E0E0E"
      />
      <path
        d="M226.934 14.4463V18.1904H213.181V1.06061H226.616V4.8047H217.977V7.6923H225.588V11.314H217.977V14.4463H226.934Z"
        fill="#0E0E0E"
      />
      <path
        d="M239.994 13.6388H237.352V18.1904H232.506V1.06061H240.337C241.887 1.06061 243.233 1.32164 244.375 1.84369C245.517 2.34943 246.398 3.08356 247.018 4.04609C247.638 4.99231 247.948 6.10983 247.948 7.39864C247.948 8.63851 247.654 9.7234 247.067 10.6533C246.496 11.5669 245.672 12.2847 244.595 12.8068L248.29 18.1904H243.102L239.994 13.6388ZM243.053 7.39864C243.053 6.59925 242.8 5.97932 242.295 5.53883C241.789 5.09835 241.038 4.87811 240.043 4.87811H237.352V9.8947H240.043C241.038 9.8947 241.789 9.68262 242.295 9.25845C242.8 8.81797 243.053 8.19803 243.053 7.39864Z"
        fill="#0E0E0E"
      />
      <path
        d="M262.442 12.0482V18.1904H257.597V11.9747L251.063 1.06061H256.177L260.24 7.86359L264.302 1.06061H269L262.442 12.0482Z"
        fill="#0E0E0E"
      />
      <path
        d="M85.0477 30.2589V36.2298H93.6371V38.3344H85.0477V45.2597H82.6006V28.1299H94.6894V30.2589H85.0477Z"
        fill="#0E0E0E"
      />
      <path d="M110.433 28.1299H112.88V45.2597H110.433V28.1299Z" fill="#0E0E0E" />
      <path
        d="M145.039 28.1299V45.2597H143.032L132.754 32.4858V45.2597H130.307V28.1299H132.314L142.592 40.9038V28.1299H145.039Z"
        fill="#0E0E0E"
      />
      <path
        d="M173.379 40.9772H164.276L162.392 45.2597H159.871L167.629 28.1299H170.051L177.833 45.2597H175.264L173.379 40.9772ZM172.523 39.0196L168.828 30.626L165.132 39.0196H172.523Z"
        fill="#0E0E0E"
      />
      <path
        d="M207.419 28.1299V45.2597H205.412L195.134 32.4858V45.2597H192.687V28.1299H194.694L204.972 40.9038V28.1299H207.419Z"
        fill="#0E0E0E"
      />
      <path
        d="M232.504 45.4555C230.791 45.4555 229.242 45.0802 227.855 44.3298C226.485 43.563 225.408 42.5189 224.625 41.1975C223.842 39.8597 223.45 38.3588 223.45 36.6948C223.45 35.0308 223.842 33.538 224.625 32.2166C225.408 30.8788 226.493 29.8347 227.879 29.0843C229.266 28.3175 230.816 27.9341 232.529 27.9341C233.867 27.9341 235.09 28.1625 236.2 28.6193C237.309 29.0598 238.255 29.7205 239.038 30.6015L237.448 32.1432C236.159 30.7891 234.552 30.1121 232.627 30.1121C231.354 30.1121 230.204 30.3976 229.176 30.9685C228.149 31.5395 227.341 32.3308 226.754 33.3423C226.183 34.3374 225.897 35.4549 225.897 36.6948C225.897 37.9347 226.183 39.0603 226.754 40.0718C227.341 41.067 228.149 41.8501 229.176 42.421C230.204 42.992 231.354 43.2775 232.627 43.2775C234.536 43.2775 236.142 42.5923 237.448 41.222L239.038 42.7636C238.255 43.6446 237.301 44.3135 236.175 44.7703C235.066 45.2271 233.842 45.4555 232.504 45.4555Z"
        fill="#0E0E0E"
      />
      <path
        d="M267.259 43.1307V45.2597H254.828V28.1299H266.917V30.2589H257.275V35.5202H265.865V37.6002H257.275V43.1307H267.259Z"
        fill="#0E0E0E"
      />
    </svg>
  );
};
export default LogoDark;
