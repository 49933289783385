import React from 'react';

type IconCategory = 'main' | 'sidebar';

interface IProps {
  name: string;
  category?: IconCategory;
  className?: string;
}

const IconCreator: React.FunctionComponent<IProps> = ({ name, category, className }) => {
  const iconURL = category
    ? `${process.env.PUBLIC_URL}/images/${category}/${name}.svg`
    : `${process.env.PUBLIC_URL}/images/${name}.svg`;
  return <img src={iconURL} alt={name} className={className} />;
};
export default IconCreator;
