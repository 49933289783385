import React from 'react';
import ContentCard from 'components/global/content-card';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import TooltipValueOutput from 'components/global/outputs/TooltipValueOutput';
import Output from 'components/global/outputs/Output';
import moment from 'moment';
import { getDates } from 'pages/dashboard/data';

interface ChartProps {
  data: any[];
}

const Runaway: React.FunctionComponent<ChartProps> = ({ data }) => {
  const chartData: any[] = [];
  data.forEach(line => {
    const time = moment(line.timestamp * 1000).format('MMM DD');
    chartData.unshift({
      date: time,
      'APY 2.5k': Number(line.runway2dot5k).toFixed(4),
      'APY 5k': Number(line.runway5k).toFixed(4),
      'APY 7.5k': Number(line.runway7dot5k).toFixed(4),
      'APY 10k': Number(line.runway10k).toFixed(4),
      'APY 20k': Number(line.runway20k).toFixed(4),
      'APY 50k': Number(line.runway50k).toFixed(4),
      'APY 70k': Number(line.runway70k).toFixed(4),
      'APY 100k': Number(line.runway100k).toFixed(4),
      'APY Current': Number(line.runwayCurrent).toFixed(4),
    });
  });

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            border: 'none',
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            padding: 15,
          }}
        >
          {payload.map(item => (
            <div
              key={`${item.dataKey}-${item.value}`}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: 5,
              }}
            >
              <TooltipValueOutput
                color={item.color || '#000000'}
                value={`${item.dataKey?.toString()}:` || '--'}
              />
              <Output value={item.value?.toString() || '--'} />
            </div>
          ))}
          <p style={{ padding: 0, margin: 0, marginTop: 10 }}>{label}</p>
        </div>
      );
    }

    return null;
  };
  const days = getDates(data);
  const headerValue = data[0].runwayCurrent.split('.')[0];
  return (
    <ContentCard title="Runway Available" maxWidth={false}>
      <p className="chart-title-value">{headerValue} Days</p>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart data={chartData} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
          <XAxis
            dataKey="date"
            style={{ stroke: '#ffffff' }}
            tickLine={false}
            strokeWidth={2}
            tick={{
              strokeWidth: 1,
              fontWeight: 100,
            }}
            ticks={days}
          />
          <YAxis style={{ stroke: '#ffffff', fontWeight: 100 }} axisLine={false} tickLine={false} />
          <CartesianGrid strokeDasharray="2" vertical={false} style={{ opacity: 0.2 }} />
          <Tooltip content={<CustomTooltip />} />
          <Line type="monotone" dataKey="APY 2.5k" stroke="#d50000" dot />
          <Line type="monotone" dataKey="APY 5k" stroke="#aa00ff" dot />
          <Line type="monotone" dataKey="APY 7.5k" stroke="#304ffe" dot />
          <Line type="monotone" dataKey="APY 10k" stroke="#00b8d4" dot />
          <Line type="monotone" dataKey="APY 20k" stroke="#00c853" dot />
          <Line type="monotone" dataKey="APY 50k" stroke="#ffd600" dot />
          <Line type="monotone" dataKey="APY 70k" stroke="#ff6d00" dot />
          <Line type="monotone" dataKey="APY 100k" stroke="#00bfa5" dot />
          <Line type="monotone" dataKey="APY Current" stroke="white" dot />
        </LineChart>
      </ResponsiveContainer>
    </ContentCard>
  );
};
export default Runaway;
