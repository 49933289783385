import {
  CreateDaiContract,
  CreateMonkContract,
  CreateStakingContract,
  CreateZenContract,
} from 'services/Contracts';
import BigNumber from 'bignumber.js';
import Utils from 'libs/Utils';
import { ethers } from 'ethers';
import { BASE_TOKEN_DECIMALS, QUOTE_TOKEN_DECIMALS } from 'constants/CoinsAddress';
import ContractAddress from 'constants/ContractAddress';
import { addComma } from 'utils/numbersUtils';

BigNumber.config({ DECIMAL_PLACES: 4 });

export const getAPYValue = async (connectedAccount: string | null): Promise<string> => {
  const zenContract = CreateZenContract();
  const stakingContract = CreateStakingContract();
  if (zenContract && stakingContract) {
    const hadStake = connectedAccount ? await zenContract._isN(connectedAccount) : false;
    const epoch = await stakingContract.epoch();
    const circ = await zenContract.circulatingSupply();
    const stakingReward = epoch.distribute;
    const stakingRebase = new BigNumber(stakingReward.toString())
      .div(circ.toString())
      .times(hadStake ? 1 : 0.988);
    const stakingAPY = stakingRebase
      .plus(1)
      .pow(365 * 3)
      .minus(1);
    const trimmedStakingAPY = Utils.trim(stakingAPY.toNumber() * 100, 1);
    return new Intl.NumberFormat('en-US').format(Number(trimmedStakingAPY));
  }
  return '0';
};

export const getBoostAmount = async (): Promise<string> => {
  const zenContract = CreateZenContract();
  const stakingContract = CreateStakingContract();
  if (zenContract && stakingContract) {
    const epoch = await stakingContract.epoch();
    const circ = await zenContract.circulatingSupply();
    const stakingReward = epoch.distribute;
    const stakingRebase = new BigNumber(stakingReward.toString()).div(circ.toString());
    const normalStakingRebase = new BigNumber(stakingReward.toString())
      .div(circ.toString())
      .times(0.988);
    const stakingAPY = stakingRebase
      .plus(1)
      .pow(365 * 3)
      .minus(1);
    const normalStakingAPY = normalStakingRebase
      .plus(1)
      .pow(365 * 3)
      .minus(1);
    const difference = stakingAPY.minus(normalStakingAPY);
    return Utils.trim(difference.toNumber() * 100, 1);
  }
  return '0';
};

export const getTVLValue = async (): Promise<void> => {};

export const getCurrentIndex = async (): Promise<string> => {
  const stakingContract = CreateStakingContract();
  if (stakingContract) {
    const currentIndexBigNumber = await stakingContract.index();
    const currentIndex = new BigNumber(currentIndexBigNumber.toString())
      .div(new BigNumber(10).pow(BASE_TOKEN_DECIMALS))
      .toNumber();
    return Utils.trim(currentIndex, 4);
  }
  return '0';
};

export const getAPDValue = (APY: number, days: number): number => {
  const stakingRebase = Math.pow(APY / 100 + 1, 1 / (365 * 3));
  return Math.pow(stakingRebase, days * 3) - 1;
};

export const getTotalStaked = async (): Promise<string> => {
  const zenContract = CreateZenContract();
  if (zenContract) {
    const circ = await zenContract.circulatingSupply();
    return ethers.BigNumber.from(circ)
      .div(ethers.BigNumber.from(10).pow(BASE_TOKEN_DECIMALS))
      .toString();
  }
  return '0';
};

export const getTreasuryBalance = async (): Promise<string> => {
  const daiContract = CreateDaiContract();
  if (!daiContract) {
    return '0';
  }
  const balance = await daiContract.balanceOf(ContractAddress.TREASURY_ADDRESS);
  return ethers.BigNumber.from(balance)
    .div(ethers.BigNumber.from(10).pow(QUOTE_TOKEN_DECIMALS))
    .toString();
};

export const getMarketCap = async (): Promise<number> => {
  const monkContract = CreateMonkContract();
  if (!monkContract) {
    return 0;
  }
  const total = await monkContract.totalSupply();
  return new BigNumber(total.toString()).div(new BigNumber(10).pow(BASE_TOKEN_DECIMALS)).toNumber();
};

export const getCircSupply = async (): Promise<string> => {
  const monkContract = CreateMonkContract();
  if (!monkContract) {
    return '0';
  }
  const total = await monkContract.totalSupply();
  const balance = await monkContract.balanceOf(ContractAddress.STAKING_ADDRESS);
  const totalNumber = new BigNumber(total.toString())
    .div(new BigNumber(10).pow(BASE_TOKEN_DECIMALS))
    .toNumber();
  const balanceNumber = new BigNumber(balance.toString())
    .div(new BigNumber(10).pow(BASE_TOKEN_DECIMALS))
    .toNumber();
  return `${addComma(totalNumber, true)} / ${addComma(totalNumber - balanceNumber, true)}`;
};
