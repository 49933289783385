import React, { useEffect, useState } from 'react';
import { GraphQLClient } from 'graphql-request';
import { Col, Row } from 'antd';
import useTitle from 'hooks/useTitle';
import UseCaseLayout from 'components/layouts/use-case';
import TotalValue from './components/total-value';
import MarketValue from './components/market-value';
import RiskFree from './components/risk-free';
import ProtocolOwned from './components/protocol-owned';
import TokenStaked from './components/token-staked';
import Runaway from './components/runaway';
import Statistics from './components/statistics';
import { dashboardDataQuery, endpoint } from './data';
import './_styles.scss';
import { getCurrentIndex, getMarketCap } from 'services/Statistics';
import { useContextState } from 'context/context';

interface IDashboardCol {
  component: React.ReactElement;
  key: string;
}

const DashboardPage: React.FunctionComponent = () => {
  const { tokenPrice } = useContextState();
  const [data, setData] = useState<any[] | null>(null);
  const [staked, setStaked] = useState<any[] | null>(null);
  const [backingPerMonk, setBackingPerMonk] = useState<number | null>(null);
  const [currentIndex, setCurrentIndex] = useState<string>('0');
  const [marketCap, setMarketCap] = useState<number>(0);
  useTitle({ title: 'Dashboard' });
  const cols: IDashboardCol[] = [];

  if (data) {
    cols.push(
      { key: '1', component: <TotalValue data={data} /> },
      { key: '2', component: <MarketValue data={data} /> },
      { key: '3', component: <RiskFree data={data} /> },
      { key: '4', component: <ProtocolOwned data={data} /> },
      { key: '5', component: <TokenStaked data={staked} /> },
      { key: '6', component: <Runaway data={data} /> },
    );
  }

  useEffect(() => {
    const client = new GraphQLClient(endpoint, { headers: {} });
    client
      .request({
        document: dashboardDataQuery,
      })
      .then(response => {
        const metrics = (response.protocolMetrics as any[]).filter(
          (item: any) => item.treasuryMarketValue > 0,
        );
        setData(metrics);
        const stakedArray = response.protocolMetrics
          .map((entry: any) => ({
            staked:
              (parseFloat(entry.zenCirculatingSupply) / parseFloat(entry.monkCirculatingSupply)) *
              100,
            timestamp: entry.timestamp,
          }))
          .filter((pm: any) => pm.staked < 100);
        setStaked(stakedArray);
        const latestMetrics = response.protocolMetrics[0];
        setBackingPerMonk(latestMetrics.treasuryMarketValue / latestMetrics.monkCirculatingSupply);
        getCurrentIndex().then(value => {
          setCurrentIndex(value);
        });
        getMarketCap().then(cap => {
          setMarketCap(cap);
        });
        // getCircSupply().then(res => {
        //   setCircSupply(res);
        // });
      });
  }, []);

  return (
    <UseCaseLayout activeKey="1" className="dashboard-container" maxWidth={false}>
      <Statistics
        backingPerMonk={backingPerMonk}
        currentIndex={currentIndex}
        marketCap={marketCap}
        tokenPrice={tokenPrice}
        lastData={data ? data[0] : null}
      />
      <Row
        gutter={16}
        style={{
          margin: '16px 0',
          justifyContent: 'center',
        }}
      >
        {cols.map((col, idx) => (
          <Col
            key={col.key}
            xs={24}
            md={12}
            style={{
              marginBottom: 16,
              paddingLeft: idx % 2 === 0 ? 0 : 8,
              paddingRight: idx % 2 === 1 ? 0 : 8,
            }}
          >
            {col.component}
          </Col>
        ))}
      </Row>
    </UseCaseLayout>
  );
};
export default DashboardPage;
