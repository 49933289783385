import React, { createContext, Dispatch, useReducer, useContext } from 'react';
import { IContextState, ContextActionTypes } from './types';
import ContextReducer, { initialState } from './reducers';

export const ContextState = createContext<IContextState>(initialState);
export const ContextDispatch = createContext<Dispatch<ContextActionTypes> | undefined>(undefined);

export const ContextProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(ContextReducer, initialState);
  return (
    <ContextState.Provider value={state}>
      <ContextDispatch.Provider value={dispatch}>{children}</ContextDispatch.Provider>
    </ContextState.Provider>
  );
};

export const useContextState = (): IContextState => {
  const context = useContext(ContextState);
  if (context === undefined) {
    throw new Error('Please use within ContextStateProvider');
  }
  return context;
};

export const useContextDispatch = (): Dispatch<ContextActionTypes> => {
  const context = useContext(ContextDispatch);
  if (context === undefined) {
    throw new Error('Please use within ContextDispatchProvider');
  }
  return context;
};
