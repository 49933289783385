import React, { useState } from 'react';
import UseCaseLayout from 'components/layouts/use-case';
import ContentCard from 'components/global/content-card';
import BondHeader from './components/header';
import BondMain from './components/main';
import BondTable from './components/table';
import BondDialogModal from './components/dialog-modal';
import { IBondData } from 'models/Bond';

const BondPage: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const [bond, setBond] = useState<IBondData | null>(null);
  const isSoldOut = false;

  const onOpen = (bondObj: IBondData) => {
    setOpen(true);
    setBond(bondObj);
  };

  return (
    <UseCaseLayout activeKey="3" className="bond-page">
      <ContentCard noPadding header={<BondHeader />}>
        <BondMain />
        <BondTable onOpen={onOpen} isSoldOut={isSoldOut} />
        <BondDialogModal
          bond={bond}
          open={open}
          onClose={() => setOpen(false)}
          isSoldOut={isSoldOut}
        />
      </ContentCard>
    </UseCaseLayout>
  );
};
export default BondPage;
