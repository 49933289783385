import React from 'react';

interface ICircleProps {
  color: string;
}

const Circle: React.FunctionComponent<ICircleProps> = ({ color }) => {
  return (
    <div
      style={{
        width: 10,
        height: 10,
        borderRadius: '50%',
        display: 'inline-block',
        backgroundColor: color,
      }}
    />
  );
};
export default Circle;
