import React, { useState } from 'react';
import { Modal } from 'antd';
import TextInput from 'components/global/text-input';
import ContainedButton from 'components/global/buttons/contained-button';
import { claimGZenByNFTService } from 'services/ClaimService';
import { useContextDispatch } from 'context/context';
import { setLoading, setNotification } from 'context/actions';
import './_styles.scss';

interface Props {
  open: boolean;
  onClose: () => void;
}

const StakeNftDialogModal: React.FunctionComponent<Props> = ({ open, onClose }) => {
  const [tokenId, setTokenId] = useState('');
  const dispatch = useContextDispatch();

  const onClaimNFT = async () => {
    if (tokenId === '') return;
    dispatch(setLoading(true));
    const response = await claimGZenByNFTService(tokenId);
    dispatch(setLoading(false));
    if (response) {
      dispatch(
        setNotification({
          type: response.success ? 'success' : 'error',
          title: response.success ? 'SUCCESS' : 'ERROR',
          description: response.message,
        }),
      );
      if (response.success) {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    }
  };

  const InputRow = (
    <div className="input-row">
      <TextInput
        className="input-row-input"
        onChange={e => setTokenId(e.target.value)}
        type="number"
        value={tokenId}
        placeholder="NFT index"
      />
      <ContainedButton
        text="Claim Now"
        className="stake-button"
        onClick={onClaimNFT}
        disabled={tokenId === ''}
      />
    </div>
  );

  return (
    <Modal
      title="Claim Your Staked NFT"
      visible={open}
      footer={null}
      maskStyle={{
        boxShadow:
          '0px 7px 12px rgba(0, 0, 0, 0.0392157), 0px 13px 14px rgba(0, 0, 0, 0.0784314), 0px 20px 15px rgba(0, 0, 0, 0.0392157), 0px 2px 4px rgba(0, 0, 0, 0.501961)',
        borderRadius: 15,
        backdropFilter: 'blur(4px)',
        verticalAlign: 'center',
      }}
      centered
      onCancel={onClose}
    >
      {InputRow}
    </Modal>
  );
};
export default StakeNftDialogModal;
