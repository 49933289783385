import { useContextState } from 'context/context';
import React, { useEffect, useState } from 'react';
import { getAPYValue, getTotalStaked, getTreasuryBalance } from 'services/Statistics';
import { addComma } from 'utils/numbersUtils';
import './_styles.scss';

const Statistics: React.FunctionComponent = () => {
  const { connectedAccount } = useContextState();

  const [apy, setApy] = useState<string>('0');
  const [totalStaked, setTotalStaked] = useState<string>('0');
  const [treasury, setTreasury] = useState<string>('0');

  useEffect(() => {
    (async () => {
      setTotalStaked(await getTotalStaked());
      setTreasury(await getTreasuryBalance());
      if (!connectedAccount) return;
      setApy(await getAPYValue(connectedAccount));
    })();
  }, [connectedAccount]);

  return (
    <section className="statistics-section">
      <div className="statistics-section-box">
        <p className="statistics-section-title">Total Staked</p>
        <p className="statistics-section-value">{addComma(Number(totalStaked), true)}</p>
      </div>
      <div className="statistics-section-box">
        <p className="statistics-section-title">Treasury Balance</p>
        <p className="statistics-section-value">${addComma(Number(treasury), true)}</p>
        {/*<p className="statistics-section-value">$793,185,937</p>*/}
      </div>
      <div className="statistics-section-box">
        <p className="statistics-section-title">Current APY</p>
        <p className="statistics-section-value">{apy} %</p>
      </div>
    </section>
  );
};
export default Statistics;
