import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Drawer } from 'antd';
import ContainedButton from 'components/global/buttons/contained-button';
import IconCreator from 'components/global/icon-creator';
import Content from './components/Content';
import './_styles.scss';

interface SideBarProps {
  activeKey: string;
}

const SideBar: React.FunctionComponent<SideBarProps> = ({ activeKey }) => {
  const [visible, setVisible] = useState(false);
  const isDesktop = useMediaQuery({
    query: '(min-width: 1440px)',
  });
  const notSmallScreen = useMediaQuery({
    query: '(min-width: 360px)',
  });

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="sidebar">
      {isDesktop ? (
        <Content activeKey={activeKey} />
      ) : (
        <>
          <ContainedButton
            text={<IconCreator name="open" category="sidebar" />}
            className="sidebar-open-btn"
            style={{ left: 20 }}
            onClick={showDrawer}
          />
          <Drawer
            placement="left"
            width={notSmallScreen ? 300 : 252}
            height="100%"
            onClose={onClose}
            visible={visible}
            closable={false}
            bodyStyle={{ padding: 0, border: 'none' }}
            maskStyle={{ backdropFilter: 'blur(4px)' }}
          >
            <Content activeKey={activeKey} />
            {visible ? (
              <ContainedButton
                text={<IconCreator name="close" category="sidebar" />}
                style={{
                  width: 50,
                  minWidth: 50,
                  height: 50,
                  padding: 0,
                  position: 'fixed',
                  top: 20,
                  left: notSmallScreen ? 300 : 252,
                  zIndex: 10,
                }}
                onClick={onClose}
              />
            ) : null}
          </Drawer>
        </>
      )}
    </div>
  );
};
export default SideBar;
