import { ethers } from 'ethers';
import ContractAddress from 'constants/ContractAddress';

import ZenABI from 'constants/artifact/StakedMonasteryMonkERC20.sol/StakedMonasteryERC20.json';
import MonkABI from 'constants/artifact/MonasteryMonkERC20.sol/MonasteryERC20.json';
import DaiABI from 'constants/artifact/mocks/DAI.sol/DAI.json';
import TreasuryABI from 'constants/artifact/MonasteryTreasury.sol/MonasteryTreasury.json';
import CalculatorABI from 'constants/artifact/MonasteryBondingCalculator.sol/MonasteryBondingCalculator.json';
import StakingABI from 'constants/artifact/MonasteryStaking.sol/MonasteryStaking.json';
import StakingHelperABI from 'constants/artifact/MonasteryStakingHelper.sol/MonasteryStakingHelper.json';
import StakingWarmupABI from 'constants/artifact/MonasteryStakingWarmup.sol/MonasteryStakingWarmup.json';
import StakingDistributerABI from 'constants/artifact/MonasteryStakingDistributor.sol/MonasteryStakingDistributor.json';
import BondDepositABI from 'constants/artifact/MonasteryBondDepository.sol/MonasteryBondDepository.json';
import NftABI from 'constants/artifact/mocks/NFT.sol/NFT.json';
import ERC20ABI from 'constants/artifact/MonasteryMonkERC20.sol/MonasteryERC20.json';
import MonasteryClaimGZen from 'constants/artifact/MonasteryClaimGZen.json';

// @ts-ignore
const { ethereum } = window;

// TODO Change URLs

// const url = 'https://eth-mainnet.alchemyapi.io/v2/aaL51HzDxBEAh_nUYJqpLgLCh0nAJc5u'; // Mainnet URL
// const url = 'https://eth-rinkeby.alchemyapi.io/v2/pdWXVJue8dcj0Z1ZNgxtyhDpoAciiqmW'; // Testnet URL
const url = 'https://data-seed-prebsc-1-s3.binance.org:8545'; // Testnet URL
const CreateZenContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(ContractAddress.ZEN_ADDRESS, ZenABI.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(ContractAddress.ZEN_ADDRESS, ZenABI.abi, provider);
};

const CreateTreasuryContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(ContractAddress.TREASURY_ADDRESS, TreasuryABI.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(ContractAddress.TREASURY_ADDRESS, TreasuryABI.abi, provider);
};

const CreateStakingContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(ContractAddress.STAKING_ADDRESS, StakingABI.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(ContractAddress.STAKING_ADDRESS, StakingABI.abi, provider);
};

const CreateStakingDistributerContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(
      ContractAddress.STAKING_DISTRIBUTER,
      StakingDistributerABI.abi,
      signer,
    );
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(
    ContractAddress.STAKING_DISTRIBUTER,
    StakingDistributerABI.abi,
    provider,
  );
};

const CreateStakingHelperContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(
      ContractAddress.STAKING_HELPER_ADDRESS,
      StakingHelperABI.abi,
      signer,
    );
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(
    ContractAddress.STAKING_HELPER_ADDRESS,
    StakingHelperABI.abi,
    provider,
  );
};

const CreateStakingWarmupContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(
      ContractAddress.STAKING_WARMUP_ADDRESS,
      StakingWarmupABI.abi,
      signer,
    );
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(
    ContractAddress.STAKING_WARMUP_ADDRESS,
    StakingWarmupABI.abi,
    provider,
  );
};

const CreateBondDepositoryContract = (
  type: 'dai' | 'new-dai' | 'daiMonk',
): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(
      ContractAddress[
        type === 'dai'
          ? 'DAIBONDING_ADDRESS'
          : type === 'new-dai'
          ? 'NEW_DAIBONDING_ADDRESS'
          : 'DAIMONKBONDING_ADDRESS'
      ],
      BondDepositABI.abi,
      signer,
    );
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(
    ContractAddress[
      type === 'dai'
        ? 'DAIBONDING_ADDRESS'
        : type === 'new-dai'
        ? 'NEW_DAIBONDING_ADDRESS'
        : 'DAIMONKBONDING_ADDRESS'
    ],
    BondDepositABI.abi,
    provider,
  );
};

const CreateCalculatorContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(
      ContractAddress.MONK_BONDING_CALC_ADDRESS,
      CalculatorABI.abi,
      signer,
    );
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(
    ContractAddress.MONK_BONDING_CALC_ADDRESS,
    CalculatorABI.abi,
    provider,
  );
};

const CreateDaiContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(ContractAddress.DAI_ADDRESS, DaiABI.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(ContractAddress.DAI_ADDRESS, DaiABI.abi, provider);
};

const CreateLPContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(ContractAddress.LP_ADDR, DaiABI.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(ContractAddress.LP_ADDR, DaiABI.abi, provider);
};

const CreateNftContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(ContractAddress.NFT_ADDRESS, NftABI.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(ContractAddress.NFT_ADDRESS, NftABI.abi, provider);
};

const CreateMonkContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(ContractAddress.MONK_ADDRESS, MonkABI.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(ContractAddress.MONK_ADDRESS, MonkABI.abi, provider);
};

const CreateTokenContract = (address: string): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(address, ERC20ABI.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(address, ERC20ABI.abi, provider);
};

const CreateClaimContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(ContractAddress.CLAIM_ADDRESS, MonasteryClaimGZen.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(url);
  return new ethers.Contract(ContractAddress.CLAIM_ADDRESS, MonasteryClaimGZen.abi, provider);
};

export {
  CreateZenContract,
  CreateTreasuryContract,
  CreateStakingContract,
  CreateNftContract,
  CreateCalculatorContract,
  CreateMonkContract,
  CreateDaiContract,
  CreateStakingDistributerContract,
  CreateStakingHelperContract,
  CreateStakingWarmupContract,
  CreateBondDepositoryContract,
  CreateTokenContract,
  CreateLPContract,
  CreateClaimContract,
};
