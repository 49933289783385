import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useContextState } from 'context/context';
import { getTreasuryBalance } from 'services/Statistics';
import { addComma } from 'utils/numbersUtils';
import './_styles.scss';

const BondMain: React.FunctionComponent = () => {
  const { tokenPrice } = useContextState();
  const [treasury, setTreasury] = useState<string>('0');

  useEffect(() => {
    (async () => {
      setTreasury(await getTreasuryBalance());
    })();
  }, []);

  return (
    <>
      <Row className="bond-main">
        <Col md={12} xs={24} className="bond-main-col">
          <p className="bond-main-title" style={{ marginBottom: 8 }}>
            Treasury Balance
          </p>
          <p className="bond-main-values">${addComma(Number(treasury))}</p>
        </Col>
        <Col md={12} xs={24} className="bond-main-col">
          <p className="bond-main-title" style={{ marginBottom: 8 }}>
            Token Price
          </p>
          <p className="bond-main-values">${tokenPrice}</p>
        </Col>
      </Row>
      <hr className="bond-main-divider" />
    </>
  );
};
export default BondMain;
