import React from 'react';
import ContentCard from 'components/global/content-card';
import TooltipValueOutput from 'components/global/outputs/TooltipValueOutput';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  AreaChart,
  CartesianGrid,
  Area,
  Tooltip,
} from 'recharts';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import PercentageOutput from 'components/global/outputs/PercentageOutput';
import moment from 'moment';
import { addComma } from 'utils/numbersUtils';
import { getDates } from 'pages/dashboard/data';

interface ChartProps {
  data: any[] | null;
}

const TokenStaked: React.FunctionComponent<ChartProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  const chartData: any[] = [];
  data.forEach(line => {
    const time = moment(line.timestamp * 1000).format('MMM DD');
    chartData.unshift({
      date: time,
      staked: line.staked,
    });
  });

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            border: 'none',
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            padding: 15,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: 5,
            }}
          >
            <TooltipValueOutput color="#007a99" value="Staked" />
            <PercentageOutput value={addComma(Number(payload[0].value), true)} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <TooltipValueOutput color="gray" value="Not Staked" />
            <PercentageOutput value={addComma(100 - Number(payload[0].value), true)} />
          </div>
          <p style={{ padding: 0, margin: 0, marginTop: 10 }}>{label}</p>
        </div>
      );
    }

    return null;
  };

  const headerValue = addComma(Number(data[0].staked), true);
  const days = getDates(data);
  return (
    <ContentCard title="Monk Staked" maxWidth={false}>
      <p className="chart-title-value">{addComma(Number(headerValue), true)}% Today</p>
      <ResponsiveContainer width="100%" height={350}>
        <AreaChart data={chartData} margin={{ top: 10, right: 0, left: -10, bottom: 0 }}>
          <defs>
            <linearGradient id="staked" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#007a99" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#007a99" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            style={{ stroke: '#ffffff' }}
            tickLine={false}
            strokeWidth={2}
            tick={{
              strokeWidth: 1,
              fontWeight: 100,
            }}
            ticks={days}
          />
          <YAxis
            style={{ stroke: '#ffffff', fontWeight: 100 }}
            axisLine={false}
            tickLine={false}
            tickFormatter={value => `${value}%`}
          />
          <CartesianGrid strokeDasharray="2" vertical={false} style={{ opacity: 0.2 }} />
          <Tooltip content={<CustomTooltip />} />

          <Area type="natural" dataKey="staked" stroke="none" fillOpacity={1} fill="url(#staked)" />
        </AreaChart>
      </ResponsiveContainer>
    </ContentCard>
  );
};
export default TokenStaked;
