import axios from 'axios';

export const CoinsAddress: Record<string, string> = {
  '0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa': 'dai',
};

export async function getTokenPrice(tokenId = 'dai'): Promise<number> {
  try {
    const resp = (await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${tokenId}&vs_currencies=usd`,
    )) as {
      data: { [id: string]: { usd: number } };
    };
    return resp.data[tokenId].usd;
  } catch (e) {
    return 0;
  }
}

export const BASE_TOKEN_DECIMALS = 9;
export const QUOTE_TOKEN_DECIMALS = 18;

export const networkChainID = {
  testnet: 97,
  mainnet: 1,
};
