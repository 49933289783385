import { IResponse } from 'models/@types';
import { CreateClaimContract, CreateNftContract, CreateTokenContract } from 'services/Contracts';
import ContractAddress from 'constants/ContractAddress';
import BigNumber from 'bignumber.js';
import { BASE_TOKEN_DECIMALS } from 'constants/CoinsAddress';

export const NftApproveToken = async (tokenId: number): Promise<IResponse | undefined> => {
  const nftContract = CreateNftContract();
  if (!nftContract) return;

  const approved = await nftContract.getApproved(tokenId);
  if (approved.toLowerCase() === ContractAddress.CLAIM_ADDRESS.toLowerCase()) {
    return { success: true, message: '' };
  }

  try {
    const approve = await nftContract.approve(ContractAddress.CLAIM_ADDRESS, tokenId);
    await approve.wait();
    return {
      success: !!approve,
      message: !!approve ? '' : 'Something went wrong',
    };
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return {
      success: false,
      message: errorMessage,
    };
  }
};

export const stakeNFTService = async (index: string): Promise<IResponse | undefined> => {
  try {
    const claimContract = CreateClaimContract();
    if (!claimContract) return;
    const approved = await NftApproveToken(+index);
    if (approved?.success) {
      const response = await claimContract.StakeNFT(+index);
      await response.wait();
      return {
        success: !!response,
        message: !!response
          ? 'Your transaction has been successfully done!'
          : 'Something went wrong.',
      };
    }
    return {
      success: false,
      message: approved?.message || '',
    };
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return {
      success: false,
      message: errorMessage,
    };
  }
};

export const unStakeNFTService = async (index: string): Promise<IResponse | undefined> => {
  try {
    const claimContract = CreateClaimContract();
    if (!claimContract) return;
    const response = await claimContract.unstake(+index);
    await response.wait();
    return {
      success: !!response,
      message: !!response
        ? 'Your transaction has been successfully done!'
        : 'Something went wrong.',
    };
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return {
      success: false,
      message: errorMessage,
    };
  }
};

export const claimGZenByNFTService = async (index: string): Promise<IResponse | undefined> => {
  try {
    const claimContract = CreateClaimContract();
    if (!claimContract) return;
    const response = await claimContract.claimPerNFT(+index);
    await response.wait();
    return {
      success: !!response,
      message: !!response
        ? 'Your transaction has been successfully done!'
        : 'Something went wrong.',
    };
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return {
      success: false,
      message: errorMessage,
    };
  }
};

export const claimYieldedNFTService = async (): Promise<IResponse | undefined> => {
  try {
    const claimContract = CreateClaimContract();
    if (!claimContract) return;
    const response = await claimContract.claimPayout();
    await response.wait();
    return {
      success: !!response,
      message: !!response ? 'Your transaction has been successfully done!' : 'Something went wrong',
    };
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return {
      success: false,
      message: errorMessage,
    };
  }
};

export interface IClaimData {
  payout: number;
  nftNumber: number;
}

export const getClaimData = async (): Promise<IClaimData | undefined> => {
  try {
    const claimContract = CreateClaimContract();
    if (!claimContract) return;
    const response = await claimContract.viewPayout();
    if (response) {
      return {
        payout: response[1].toNumber(),
        nftNumber: response[2].toNumber(),
      };
    }
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
  }
};

export const getGZenBalance = async (userAddress: string): Promise<number | undefined> => {
  try {
    const tokenContract = CreateTokenContract(ContractAddress.GZEN_ADDRESS);
    if (!tokenContract) return;
    const response = await tokenContract.balanceOf(userAddress);
    if (response) {
      return new BigNumber(response.toString())
        .div(new BigNumber(10).pow(BASE_TOKEN_DECIMALS))
        .toNumber();
    }
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
  }
};
