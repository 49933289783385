import {
  CreateBondDepositoryContract,
  CreateDaiContract,
  CreateLPContract,
  CreateTokenContract,
  CreateTreasuryContract,
} from 'services/Contracts';
import { IBondData } from 'models/Bond';
import { BASE_TOKEN_DECIMALS, QUOTE_TOKEN_DECIMALS } from 'constants/CoinsAddress';
import { ethers } from 'ethers';
import Utils from 'libs/Utils';
import ContractAddress from 'constants/ContractAddress';
import moment from 'moment';
import { IResponse } from 'models/@types';
import BigNumber from 'bignumber.js';

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

interface IBondDataArgs {
  type: 'dai' | 'new-dai' | 'daiMonk';
  userAddress: string;
}

interface IPublicBondDataArgs {
  type: 'dai' | 'new-dai' | 'daiMonk';
}

export const getPublicBondData = async (data: IPublicBondDataArgs): Promise<IBondData | null> => {
  const bondContract = CreateBondDepositoryContract(data.type);
  const daiContract = CreateDaiContract(); //
  const tokenContract = CreateTokenContract(
    data.type === 'dai'
      ? ContractAddress.DAI_ADDRESS
      : data.type === 'new-dai'
      ? ContractAddress.DAI_ADDRESS
      : ContractAddress.LP_ADDR,
  );
  if (!bondContract || !daiContract || !tokenContract) {
    return null;
  }
  try {
    const bondPriceInUSDBigNumber = await bondContract.bondPriceInUSD();

    const bondPriceInUSD = new BigNumber(bondPriceInUSDBigNumber.toString()).div(
      new BigNumber(10).pow(QUOTE_TOKEN_DECIMALS),
    );

    const bondDiscount = (price: number): number => (price - bondPriceInUSD.toNumber()) / price;

    const response = await bondContract.bondPriceInUSD();

    const purchased = await bondContract.totalDebt();

    const purchasedInUSD = new BigNumber(purchased.toString()).div(
      new BigNumber(10).pow(QUOTE_TOKEN_DECIMALS),
    );

    return {
      ROI: (price: number): string =>
        `${bondDiscount(price) ? Utils.trim(bondDiscount(price) * 100, 2) : 0} %`,
      bondPrice: bondPriceInUSD.toNumber(),
      coin: data.type,
      address:
        ContractAddress[
          data.type === 'dai'
            ? 'DAIBONDING_ADDRESS'
            : data.type === 'new-dai'
            ? 'NEW_DAIBONDING_ADDRESS'
            : 'DAIMONKBONDING_ADDRESS'
        ],
      mintPrice: new BigNumber(response.toString())
        .div(new BigNumber(10).pow(QUOTE_TOKEN_DECIMALS))
        .toFixed(2),
      pendingReward: '',
      claimableReward: '',
      maxYouCanBuy: '',
      vestingTerm: '',
      timeUntilFullyVested: '',
      youWillGet: '',
      yourBalance: '',
      redeemROI: (price: number): string => '',
      purchased: (price: number): string => Utils.trim(purchasedInUSD.toNumber(), 4),
    };
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return null;
  }
};

export const getBondData = async (data: IBondDataArgs): Promise<IBondData | null> => {
  const treasuryContract = CreateTreasuryContract();
  const bondContract = CreateBondDepositoryContract(data.type);
  const daiContract = CreateDaiContract();
  const tokenContract = CreateTokenContract(
    data.type === 'dai'
      ? ContractAddress.DAI_ADDRESS
      : data.type === 'new-dai'
      ? ContractAddress.DAI_ADDRESS
      : ContractAddress.LP_ADDR,
  );
  if (!bondContract || !daiContract || !tokenContract || !treasuryContract) {
    return null;
  }
  try {
    const maxPayoutBigNumber = await bondContract.maxPayout();
    const terms = await bondContract.terms();
    const bondPrice = await bondContract.bondPrice();
    const bondPriceInUSDBigNumber = await bondContract.bondPriceInUSD();
    // const bondPriceBigNumber = await bondContract.bondPrice();
    const userBalanceBigNumber = await tokenContract.balanceOf(data.userAddress);
    const bondInfo = await bondContract.bondInfo(data.userAddress);
    const claimableBigNumber = await bondContract.pendingPayoutFor(data.userAddress);

    const bondPriceInUSD = new BigNumber(bondPriceInUSDBigNumber.toString()).div(
      new BigNumber(10).pow(QUOTE_TOKEN_DECIMALS),
    );

    const bondRedeemPriceInUSD = new BigNumber(bondInfo[3].toString()).div(
      new BigNumber(10).pow(QUOTE_TOKEN_DECIMALS),
    );

    const userBalance = new BigNumber(userBalanceBigNumber.toString()).div(
      new BigNumber(10).pow(QUOTE_TOKEN_DECIMALS),
    );

    const youWillGet = await bondContract.payoutFor(userBalanceBigNumber.toString());

    const bondDiscount = (price: number): number => (price - bondPriceInUSD.toNumber()) / price;

    const bondRedeemDiscount = (price: number): number =>
      bondRedeemPriceInUSD.toNumber() !== 0 ? (price - bondRedeemPriceInUSD.toNumber()) / price : 0;

    const maxPayout = new BigNumber(maxPayoutBigNumber.toString()).div(
      new BigNumber(10).pow(BASE_TOKEN_DECIMALS),
    );
    const timeInSec = parseInt(terms[1]);

    const pendingReward = new BigNumber(bondInfo[0].toString())
      .div(new BigNumber(10).pow(BASE_TOKEN_DECIMALS))
      .toFixed(4);

    const claimableReward = new BigNumber(claimableBigNumber.toString())
      .div(new BigNumber(10).pow(BASE_TOKEN_DECIMALS))
      .toFixed(4);

    const response = await bondContract.bondPriceInUSD();

    const timeUntilFullyVested = moment
      .duration(new BigNumber(bondInfo[1].toString()).toNumber(), 'seconds')
      .humanize();

    const purchased = await bondContract.totalDebt();

    const purchasedInUSD = new BigNumber(purchased.toString()).div(
      new BigNumber(10).pow(BASE_TOKEN_DECIMALS),
    );

    const valuePerOneLp =
      data.type === 'daiMonk'
        ? await treasuryContract.valueOfToken(
            ContractAddress.LP_ADDR,
            new BigNumber(10).pow(QUOTE_TOKEN_DECIMALS).toString(),
          )
        : 0;

    return {
      yourBalance: userBalance.toString(),
      youWillGet: (youWillGet as ethers.BigNumber).toString() || '0',
      maxYouCanBuy: maxPayout.toString(), // terms.maxPayout
      ROI: (price: number): string =>
        `${bondDiscount(price) ? Utils.trim(bondDiscount(price) * 100, 2) : 0} %`,
      vestingTerm: moment.duration(timeInSec, 'seconds').humanize(),
      pendingReward,
      claimableReward,
      timeUntilFullyVested, // bondInfo.vesting,
      bondPrice: bondPriceInUSD.toNumber(),
      coin: data.type,
      address:
        ContractAddress[
          data.type === 'dai'
            ? 'DAIBONDING_ADDRESS'
            : data.type === 'new-dai'
            ? 'NEW_DAIBONDING_ADDRESS'
            : 'DAIMONKBONDING_ADDRESS'
        ],
      mintPrice: new BigNumber(response.toString())
        .div(new BigNumber(10).pow(QUOTE_TOKEN_DECIMALS))
        .toFixed(2),
      redeemROI: (price: number): string =>
        `${bondRedeemDiscount(price) ? Utils.trim(bondRedeemDiscount(price) * 100, 2) : 0} %`,
      purchased: (price: number): string => Utils.trim(purchasedInUSD.toNumber(), 4),
      ...(data.type === 'daiMonk' && {
        valuePerOneLp: valuePerOneLp.toNumber(),
        lpBondPrice: bondPrice.toNumber(),
      }),
    };
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return null;
  }
};

interface RedeemArgs {
  address: string;
  type: 'dai' | 'new-dai' | 'daiMonk';
  stake: boolean;
}

export const onRedeemService = async (data: RedeemArgs): Promise<IResponse | undefined> => {
  const bondContract = CreateBondDepositoryContract(data.type);
  if (!bondContract) {
    return;
  }
  try {
    const response = await bondContract.redeem(data.address, data.stake);
    await response.wait();
    if (!!response) {
      return {
        success: true,
        message: 'Your transaction successfully executed.',
      };
    }
    return {
      success: false,
      message: 'An error occurred',
    };
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return {
      success: false,
      message: errorMessage,
    };
  }
};

interface BondServiceArgs {
  type: 'dai' | 'new-dai' | 'daiMonk';
  address: string;
  amount: number;
}

export const onBondService = async (data: BondServiceArgs): Promise<IResponse | undefined> => {
  const bondContract = CreateBondDepositoryContract(data.type);
  const daiContract = CreateDaiContract();
  const lpContract = CreateLPContract();
  if (!bondContract || !daiContract || !lpContract) {
    return;
  }
  try {
    const decimalAmount = new BigNumber(data.amount).times(
      new BigNumber(10).pow(QUOTE_TOKEN_DECIMALS),
    );
    const address =
      ContractAddress[
        data.type === 'dai'
          ? 'DAIBONDING_ADDRESS'
          : data.type === 'new-dai'
          ? 'NEW_DAIBONDING_ADDRESS'
          : 'DAIMONKBONDING_ADDRESS'
      ];
    // const approved =
    //   data.type === 'dai' || data.type === 'new-dai'
    //     ? await daiContract.approve(address, decimalAmount.toString())
    //     : await lpContract.approve(address, decimalAmount.toString());
    const approved =
      data.type === 'dai' || data.type === 'new-dai'
        ? await daiContract.approve(address, new BigNumber(2).pow(256).minus(1).toString())
        : await lpContract.approve(address, new BigNumber(2).pow(256).minus(1).toString());
    await approved.wait();
    if (!!approved) {
      const bondPrice = await bondContract.bondPrice();
      const maxPrice = Math.round(Number(bondPrice.toString()) * (1 + 0.01));
      // stake function
      const response = await bondContract.deposit(decimalAmount.toString(), maxPrice, data.address);
      await response.wait();
      if (!!response) {
        return {
          success: true,
          message: 'Your transaction successfully executed.',
        };
      }
      return {
        success: false,
        message: 'An error occurred',
      };
    }
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    console.error(errorMessage);
    return {
      success: false,
      message: errorMessage,
    };
  }
};
