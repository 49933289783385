import React from 'react';
import ContentCard from 'components/global/content-card';
import {
  Area,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { AreaChart } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import TooltipValueOutput from 'components/global/outputs/TooltipValueOutput';
// import { data } from './data';
import DollarOutput from 'components/global/outputs/DollarOutput';
import moment from 'moment';
import { addComma } from 'utils/numbersUtils';
import { getDates } from 'pages/dashboard/data';

interface ChartProps {
  data: any[];
}

const RiskFree: React.FunctionComponent<ChartProps> = ({ data }) => {
  const chartData: any[] = [];
  data.forEach(line => {
    const time = moment(line.timestamp * 1000).format('MMM DD');
    chartData.unshift({
      date: time,
      value: line.treasuryDaiRiskFreeValue,
      treasury: line.treasuryRiskFreeValue,
    });
  });

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      console.log(payload);
      return (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            border: 'none',
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            padding: 15,
          }}
        >
          {payload.map(item => (
            <div
              key={item.dataKey}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: 5,
              }}
            >
              <TooltipValueOutput
                color={item.name === 'value' ? 'red' : item.name === 'treasury' ? 'green' : 'green'}
                value={
                  item.name === 'value'
                    ? 'DAI'
                    : item.name === 'treasury'
                    ? 'Treasury'
                    : '--' || '--'
                }
              />
              <DollarOutput value={addComma(Number(item.value) || 0, true)} />
            </div>
          ))}
          <p style={{ padding: 0, margin: 0, marginTop: 10 }}>{label}</p>
        </div>
      );
    }

    return null;
  };

  const headerValue = addComma(Number(data[0].treasuryDaiRiskFreeValue), true);
  const days = getDates(data);
  return (
    <ContentCard title="Risk Free Value of Treasury Assets" maxWidth={false}>
      <p className="chart-title-value">${headerValue} Today</p>
      <ResponsiveContainer width="100%" height={350}>
        <AreaChart data={chartData} margin={{ top: 10, right: 0, left: -10, bottom: 0 }}>
          <defs>
            <linearGradient id="value" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="red" stopOpacity={0.8} />
              <stop offset="95%" stopColor="red" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="treasury" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="green" stopOpacity={0.8} />
              <stop offset="95%" stopColor="green" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            style={{ stroke: '#ffffff' }}
            tickLine={false}
            strokeWidth={2}
            tick={{
              strokeWidth: 1,
              fontWeight: 100,
            }}
            ticks={days}
          />
          <YAxis
            style={{ stroke: '#ffffff', fontWeight: 100 }}
            axisLine={false}
            tickLine={false}
            tickFormatter={(value, index) => (index !== 0 ? `$${value / 1000000}M` : value)}
          />
          <CartesianGrid strokeDasharray="2" vertical={false} style={{ opacity: 0.2 }} />
          <Tooltip content={<CustomTooltip />} />

          <Area type="natural" dataKey="value" stroke="none" fillOpacity={1} fill="url(#value)" />
          <Area
            type="natural"
            dataKey="treasury"
            stroke="none"
            fillOpacity={1}
            fill="url(#treasury)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </ContentCard>
  );
};
export default RiskFree;
