import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ContextProvider } from 'context/context';
import RouterProvider from 'components/providers/Router';
import AppProvider from 'components/providers/App';
import AppLoading from 'components/global/app-loading';
import Notification from 'components/global/notification';
import './index.scss';
import 'antd/dist/antd.css';

const App: React.FunctionComponent = () => {
  return (
    <ContextProvider>
      <AppProvider>
        <BrowserRouter>
          <div className="monastery-app">
            <RouterProvider />
          </div>
          <AppLoading />
          <Notification />
        </BrowserRouter>
      </AppProvider>
    </ContextProvider>
  );
};

export default App;
