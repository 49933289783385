import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs } from 'antd';
import { useContextDispatch, useContextState } from 'context/context';
import {
  hadTokenBoosting,
  stakingTransaction,
  unBoostStakingTransaction,
  unStakingTransaction,
} from 'services/StakingServices';
import ContainedButton from 'components/global/buttons/contained-button';
import { setLoading, setNotification } from 'context/actions';
import Balance from '../balance';
import './_styles.scss';
import BoostTokenDialogModal from './components/dialog-modal';
import { getBoostAmount } from 'services/Statistics';
import InputRow from './components/input-row';
// import Switch from 'components/global/switch';

const { TabPane } = Tabs;

const StakeTabs: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const [hadBoost, setHadBoost] = useState(false);
  const [boostAmount, setBoostAmount] = useState('0');
  const { connectedAccount: address, userBalance } = useContextState();
  const dispatch = useContextDispatch();
  const [amount, setAmount] = useState('');

  useEffect(() => {
    (async () => {
      if (address) {
        setHadBoost(await hadTokenBoosting(address));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const boost = await getBoostAmount();
      setBoostAmount(new Intl.NumberFormat('en-US').format(Number(boost)));
    })();
  }, []);

  const handleUnBoostToken = async () => {
    dispatch(setLoading(true));
    const response = await unBoostStakingTransaction();
    dispatch(setLoading(false));
    if (response) {
      dispatch(
        setNotification({
          type: response.success ? 'success' : 'error',
          title: response.success ? 'SUCCESS' : 'ERROR',
          description: response.message,
        }),
      );
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  const stakeToken = async () => {
    if (+amount > 0) {
      dispatch(setLoading(true));
      const response = await stakingTransaction(+amount);
      dispatch(setLoading(false));
      if (response) {
        dispatch(
          setNotification({
            type: response.success ? 'success' : 'error',
            title: response.success ? 'SUCCESS' : 'ERROR',
            description: response.message,
          }),
        );
        if (response.success) {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      }
      // const allowance = await AllowanceToken(address);
      // if (allowance > Number(amount.current)) {
      //   await stakingTransaction(Number(amount.current));
      // } else {
      //   alert('not enough token');
      // }
    }
  };

  const unStakeToken = async () => {
    if (amount && Number(amount) > 0 && address) {
      dispatch(setLoading(true));
      const response = await unStakingTransaction(Number(amount));
      dispatch(setLoading(false));
      if (response) {
        dispatch(
          setNotification({
            type: response.success ? 'success' : 'error',
            title: response.success ? 'SUCCESS' : 'ERROR',
            description: response.message,
          }),
        );
        if (response.success) {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      }
    }
  };

  return (
    <>
      <Tabs defaultActiveKey="1" onChange={() => setAmount('')}>
        <TabPane tab="Stake" key="1">
          <InputRow
            amount={amount}
            buttonText="STAKE"
            handleForm={stakeToken}
            setAmount={setAmount}
            onMax={() => setAmount(userBalance.monkToken.toString())}
          />
          <Balance userBalance={userBalance} />
        </TabPane>
        <TabPane tab="⚡NFT BOOST⚡" key="2" style={{ margin: '0 16px' }}>
          <Row className="boost-box">
            <Col xs={24} md={12} className="boost-box-button">
              {hadBoost ? (
                <ContainedButton
                  text="Unstake Immortal Monk"
                  className="stake-monk-button"
                  onClick={handleUnBoostToken}
                />
              ) : (
                <ContainedButton
                  text="Stake Immortal Monk"
                  className="stake-monk-button"
                  onClick={() => setOpen(true)}
                />
              )}
            </Col>
            <Col xs={24} md={12} className="boost-box-text">
              <p>BOOST AMOUNT:</p> <span className="yellow-text">{boostAmount}%</span>
            </Col>
          </Row>
          <Balance userBalance={userBalance} />
          <BoostTokenDialogModal open={open} onClose={() => setOpen(false)} />
        </TabPane>
        <TabPane tab="Unstake" key="3">
          <InputRow
            amount={amount}
            buttonText="UNSTAKE"
            handleForm={unStakeToken}
            setAmount={setAmount}
            onMax={() => setAmount(userBalance.zenToken.toString())}
          />
          <Balance userBalance={userBalance} />
        </TabPane>
      </Tabs>
    </>
  );
};
export default StakeTabs;
