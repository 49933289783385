import React, { useState } from 'react';
import { Modal } from 'antd';
import TextInput from 'components/global/text-input';
import ContainedButton from 'components/global/buttons/contained-button';
import './_styles.scss';
import { boostStakingTransaction } from 'services/StakingServices';
import { useContextDispatch } from 'context/context';
import { setLoading, setNotification } from 'context/actions';

interface IBoostTokenDialogModalProps {
  open: boolean;
  onClose: () => void;
}

const BoostTokenDialogModal: React.FunctionComponent<IBoostTokenDialogModalProps> = ({
  open,
  onClose,
}) => {
  const [tokenId, setTokenId] = useState('');
  const dispatch = useContextDispatch();

  const handleBoostToken = async () => {
    if (!tokenId) return;
    dispatch(setLoading(true));
    const response = await boostStakingTransaction(+tokenId);
    dispatch(setLoading(false));
    if (response) {
      dispatch(
        setNotification({
          type: response.success ? 'success' : 'error',
          title: response.success ? 'SUCCESS' : 'ERROR',
          description: response.message,
        }),
      );
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  const InputRow = (
    <div className="input-row">
      <TextInput
        className="input-row-input"
        onChange={e => setTokenId(e.target.value)}
        type="number"
        value={tokenId}
        placeholder="Token ID"
      />
      <ContainedButton
        text="Stake Now"
        className="stake-button"
        onClick={handleBoostToken}
        disabled={tokenId === ''}
      />
    </div>
  );

  return (
    <Modal
      title="Stake Your NFT"
      visible={open}
      footer={null}
      maskStyle={{
        boxShadow:
          '0px 7px 12px rgba(0, 0, 0, 0.0392157), 0px 13px 14px rgba(0, 0, 0, 0.0784314), 0px 20px 15px rgba(0, 0, 0, 0.0392157), 0px 2px 4px rgba(0, 0, 0, 0.501961)',
        borderRadius: 15,
        backdropFilter: 'blur(4px)',
        verticalAlign: 'center',
      }}
      centered
      onCancel={onClose}
    >
      {InputRow}
    </Modal>
  );
};
export default BoostTokenDialogModal;
