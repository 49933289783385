import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconCreator from 'components/global/icon-creator';
import OutlinedButton from 'components/global/buttons/outlined-button';
import './_styles.scss';

const BackButton: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <OutlinedButton onClick={onBack} className="back-button" icon={<IconCreator name="back" />} />
  );
};
export default BackButton;
