import React from 'react';
import './_styles.scss';
// import { timeToNextRebase } from 'services/StakingServices';

const ClaimHeader: React.FunctionComponent = () => {
  // const [value, setValue] = useState<string>('Calculating...');

  // useEffect(() => {
  //   (async () => {
  //     const res = await timeToNextRebase();
  //     setValue(res);
  //   })();
  // }, []);
  return (
    <>
      <div className="claim-header">
        <p className="claim-title">Claim gZen</p>
      </div>
      <hr className="claim-header-divider" />
    </>
  );
};
export default ClaimHeader;
