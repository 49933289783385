import React from 'react';
import './_styles.scss';
import clsx from 'clsx';

interface IContentCardProps {
  title?: string;
  header?: React.ReactNode;
  noPadding?: boolean;
  maxWidth?: boolean;
}

const ContentCard: React.FunctionComponent<IContentCardProps> = ({
  title,
  header,
  noPadding,
  children,
  maxWidth = true,
}) => {
  return (
    <div
      className={clsx('content-card', { 'content-card-max-width': maxWidth })}
      style={{ padding: noPadding ? 0 : undefined }}
    >
      {header}
      {title && <p className="content-card-title">{title}</p>}
      {children}
    </div>
  );
};
export default ContentCard;
