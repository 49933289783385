import React from 'react';
import useTitle from 'hooks/useTitle';
import { useContextState } from 'context/context';
import UseCaseLayout from 'components/layouts/use-case';
import ContentCard from 'components/global/content-card';
import ConnectWallet from 'components/global/connect-wallet';
import StakeHeader from './components/header';
import StakeMain from './components/main';
import StakeTabs from './components/stake-tabs';
// revert commit
const StakePage: React.FunctionComponent = () => {
  const { connectedAccount } = useContextState();
  useTitle({ title: 'Stake' });

  return (
    <UseCaseLayout activeKey="2" className="stake-container" style={{ maxWidth: 1036 }}>
      <ContentCard noPadding header={<StakeHeader />}>
        <StakeMain />
        {connectedAccount ? (
          <StakeTabs />
        ) : (
          <>
            <ConnectWallet className="stake-connect-wallet" />
            <div className="stake-wallet-desc">Connect your wallet to stake MONK tokens!</div>
          </>
        )}
      </ContentCard>
    </UseCaseLayout>
  );
};
export default StakePage;
