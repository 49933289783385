import React from 'react';
import { useContextState } from 'context/context';
import ContainedButton from 'components/global/buttons/contained-button';
import IconCreator from 'components/global/icon-creator';
import { IBondData } from 'models/Bond';
import ConnectWallet from 'components/global/connect-wallet';

interface IMobileBoxProps {
  onClick?: () => void;
  bond: IBondData;
  connectedAddress: string | null;
}

const MobileBox: React.FunctionComponent<IMobileBoxProps> = ({
  onClick,
  bond,
  connectedAddress,
}) => {
  const { tokenPrice } = useContextState();
  return (
    <div
      style={{
        backgroundColor: '#141517',
        padding: 24,
        borderRadius: 15,
        margin: '0 16px 20px 16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <IconCreator name={bond.coin} />
        <p className="bond-table-values" style={{ marginInlineStart: 16 }}>
          {bond.coin === 'new-dai' ? 'DAI' : bond.coin === 'daiMonk' ? 'DAI-MONK LP' : bond.coin}
        </p>
        {bond.coin === 'daiMonk' && (
          <a
            href="https://app.sushi.com/add/0x6b175474e89094c44da98b954eedeac495271d0f/0xa4E27ea37D18bb0f483779f9E75A6024EFa5E73e"
            target="_blank"
            style={{ marginBottom: 16, marginInlineStart: 8 }}
          >
            <img src="/images/open-in-new.svg" alt="open in new" />
          </a>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '15px 0',
        }}
      >
        <p className="bond-table-title">Price</p>
        <p className="bond-table-values">{Number(bond.bondPrice).toFixed(4)}</p>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '16px 0',
        }}
      >
        <p className="bond-table-title">ROI</p>
        <p className="bond-table-values">{bond.ROI(tokenPrice)}</p>
      </div>
      {/*<div*/}
      {/*  style={{*/}
      {/*    display: 'flex',*/}
      {/*    alignItems: 'center',*/}
      {/*    justifyContent: 'space-between',*/}
      {/*    margin: '15px 0',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <p className="bond-table-title">Purchased</p>*/}
      {/*  <p className="bond-table-values">$9,252,926</p>*/}
      {/*</div>*/}
      {connectedAddress ? (
        <ContainedButton text="Mint" style={{ width: '100%' }} onClick={onClick} />
      ) : (
        <ConnectWallet withoutMarginInlineEnd fullWidth />
      )}
    </div>
  );
};

export default MobileBox;
