import numeral from 'numeral';

export function randomInteger(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const units = [
  [1, 's'],
  [60, 'm'],
  [60 * 60, 'h'],
  [60 * 60 * 24, 'day'],
];

export const displayTime = (seconds: number): string => {
  let bestUnit = units[0];
  for (const unit of units) {
    if (seconds >= unit[0]) {
      bestUnit = unit;
    }
  }
  const [divisor, label] = bestUnit;
  return Math.floor(seconds / +divisor).toString() + label;
};

export const addComma = (value: number, noPrecision = false): string => {
  if (noPrecision) {
    return numeral(value).format('0,0');
  }
  return numeral(value).format('0,0.0000');
};
