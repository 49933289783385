import React from 'react';

interface IOutputProps {
  value: string;
}

const Output: React.FunctionComponent<IOutputProps> = ({ value }) => {
  return <p style={{ padding: 0, margin: 0, marginLeft: 30, textAlign: 'right' }}>{value}</p>;
};

export default Output;
