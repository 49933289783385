import React from 'react';

const TwitterIcon: React.FunctionComponent = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26 5.10088C25.0431 5.52586 24.015 5.81185 22.9362 5.94085C24.038 5.28087 24.883 4.23591 25.281 2.98996C24.2511 3.60094 23.1091 4.04493 21.8932 4.28491C20.9202 3.24795 19.5342 2.59998 18.0003 2.59998C15.0544 2.59998 12.6665 4.98888 12.6665 7.93377C12.6665 8.35176 12.7145 8.75974 12.8045 9.14873C8.37167 8.92674 4.44183 6.80281 1.80992 3.57494C1.35195 4.36291 1.08896 5.27887 1.08896 6.25784C1.08896 8.10776 2.02992 9.74071 3.46087 10.6967C2.5869 10.6687 1.76393 10.4287 1.04496 10.0297C1.04496 10.0527 1.04496 10.0737 1.04496 10.0967C1.04496 12.6816 2.88289 14.8375 5.3238 15.3265C4.87681 15.4485 4.40483 15.5135 3.91785 15.5135C3.57486 15.5135 3.23987 15.4795 2.91489 15.4185C3.59386 17.5374 5.56379 19.0803 7.89769 19.1234C6.07277 20.5543 3.77286 21.4072 1.27295 21.4072C0.842968 21.4072 0.417984 21.3822 0 21.3322C2.3609 22.8453 5.16381 23.7282 8.17669 23.7282C17.9884 23.7282 23.3522 15.6005 23.3522 8.55175C23.3522 8.32076 23.3471 8.09076 23.3371 7.86178C24.3801 7.1088 25.285 6.16984 26 5.10088Z"
        fill="#0E0E0E"
      />
    </svg>
  );
};
export default TwitterIcon;
