import { INotification, IUserBalance } from 'models/@types';
import { IBond } from 'models/Bond';

export interface IContextState {
  connectedAccount: string | null;
  tokenApproved: boolean | null;
  userBalance: IUserBalance;
  bonds: IBond[];
  appLoading: boolean;
  notification: INotification | null;
  tokenPrice: number;
}

export const SET_CONNECTED_ACCOUNT = 'SET_CONNECTED_ACCOUNT';
export const SET_TOKEN_APPROVED = 'SET_TOKEN_APPROVED';
export const SET_USER_BALANCE = 'SET_USER_BALANCE';
export const SET_BONDS = 'SET_BONDS';
export const SET_LOADING = 'SET_LOADING';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_TOKEN_PRICE = 'SET_TOKEN_PRICE';

interface SetConnectedAccount {
  type: typeof SET_CONNECTED_ACCOUNT;
  payload: string | null;
}

interface SetTokenApproved {
  type: typeof SET_TOKEN_APPROVED;
  payload: boolean;
}

interface SetUserBalance {
  type: typeof SET_USER_BALANCE;
  payload: IUserBalance;
}

interface SetBonds {
  type: typeof SET_BONDS;
  payload: IBond[];
}

interface SetLoading {
  type: typeof SET_LOADING;
  payload: boolean;
}

interface SetNotification {
  type: typeof SET_NOTIFICATION;
  payload: INotification;
}

interface SetTokenPrice {
  type: typeof SET_TOKEN_PRICE;
  payload: number;
}

export type ContextActionTypes =
  | SetConnectedAccount
  | SetTokenApproved
  | SetUserBalance
  | SetBonds
  | SetNotification
  | SetTokenPrice
  | SetLoading;
