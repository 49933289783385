import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import IconCreator from 'components/global/icon-creator';
import MediumIcon from 'components/icons/Medium';
import TwitterIcon from 'components/icons/Twitter';
import DiscordIcon from 'components/icons/Discord';
import '../_styles.scss';
import GithubIcon from 'components/icons/Github';

interface IContentProps {
  activeKey: string;
}

const Content: React.FunctionComponent<IContentProps> = ({ activeKey }) => {
  const navigate = useNavigate();
  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-logo-wrapper" onClick={() => navigate('/')}>
        <IconCreator name="logo" />
      </div>
      <Menu
        defaultSelectedKeys={[`${activeKey}`]}
        // defaultOpenKeys={["sub1"]}
        mode="inline"
        // theme="dark"
        // inlineCollapsed={this.state.collapsed}
        style={{ border: 'none' }}
      >
        <Menu.Item
          key="1"
          icon={<IconCreator name="dashboard" category="sidebar" />}
          onClick={() => navigate('/dashboard')}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<IconCreator name="stake" category="sidebar" />}
          onClick={() => navigate('/stake')}
        >
          Stake
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<IconCreator name="bond" category="sidebar" />}
          onClick={() => navigate('/bond')}
        >
          Bond
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<IconCreator name="calculator" category="sidebar" />}
          onClick={() => navigate('/calculator')}
        >
          Calculator
        </Menu.Item>
        <Menu.Item
          key="5"
          icon={<IconCreator name="stake" category="sidebar" />}
          onClick={() => navigate('/claim')}
        >
          Claim GZen
        </Menu.Item>
        <Menu.Item
          key="6"
          icon={<IconCreator name="governance" category="sidebar" />}
          onClick={() => navigate('/governance')}
        >
          Governance
        </Menu.Item>
        {/*<Menu.Item*/}
        {/*  key="6"*/}
        {/*  icon={<img src={document} alt="document" />}*/}
        {/*  onClick={() => navigate('/Docs')}*/}
        {/*>*/}
        {/*  Docs*/}
        {/*</Menu.Item>*/}
      </Menu>
      <div
        style={{
          position: 'absolute',
          bottom: 26,
          left: 32,
          right: 32,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <a href="https://monasterydao.medium.com/" target="_blank" className="side-bar-social-icon">
          <MediumIcon />
        </a>
        <a
          href="https://twitter.com/ImmortaIMonks"
          target="_blank"
          className="side-bar-social-icon"
        >
          <TwitterIcon />
        </a>
        <a href="https://discord.gg/immortalmonks" target="_blank" className="side-bar-social-icon">
          <DiscordIcon />
        </a>
        <a
          href="https://monasteryfinance.gitbook.io/product-docs/"
          target="_blank"
          className="side-bar-social-icon"
        >
          <GithubIcon />
        </a>
      </div>
    </div>
  );
};
export default Content;
