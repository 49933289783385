import React, { useEffect, useState } from 'react';
import useTitle from 'hooks/useTitle';
import ContentRow from 'components/global/content-row';
import UseCaseLayout from 'components/layouts/use-case';
import ContentCard from 'components/global/content-card';
import CalculatorHeader from './components/header';
import CalculatorMain from './components/main';
import CalculatorForm from './components/form';
import { useContextState } from 'context/context';
import { getAPDValue, getAPYValue } from 'services/Statistics';
import { GetMonkBalance } from 'services/BalanceService';
import Utils from 'libs/Utils';

export interface ICalculatorForm {
  tokenAmount: number;
  apy: number;
  tokenPrice: number;
  futureTokenPrice: number;
  days: number;
  currentApy: number;
  monkPrice: number;
  monkBalance: number;
}

export interface ICalculatorCurrentValue {}

const CalculatorPage: React.FunctionComponent = () => {
  useTitle({ title: 'Calculator' });
  const { connectedAccount, tokenPrice } = useContextState();

  const [formValue, setFormValue] = useState<ICalculatorForm>({
    currentApy: 0,
    monkBalance: 0,
    monkPrice: tokenPrice,
    days: 100,
    tokenPrice,
    futureTokenPrice: tokenPrice,
    apy: 0,
    tokenAmount: 0,
  });

  useEffect(() => {
    (async () => {
      const currentApyStr = await getAPYValue(connectedAccount);
      const currentApy = Number(currentApyStr.replace(',', ''));
      const apd = getAPDValue(currentApy, formValue.days);

      if (!connectedAccount) {
        setFormValue(prev => ({
          ...prev,
          apy: currentApy,
          currentApy,
          apd,
        }));
        return;
      }
      const monkBalance = await GetMonkBalance(connectedAccount);
      setFormValue(prev => ({
        ...prev,
        apy: currentApy,
        tokenAmount: monkBalance,
        monkBalance,
        currentApy,
        apd,
      }));
    })();
  }, []);

  const convertNumberToString = (num: { value: number; prefix?: string; suffix?: string }) =>
    `${num.prefix ?? ''}${num.value}${num.suffix ?? ''}`;

  return (
    <UseCaseLayout activeKey="4" className="calculator-page">
      <ContentCard noPadding header={<CalculatorHeader />}>
        <CalculatorMain value={formValue} />
        <CalculatorForm formValue={formValue} setFormValue={setFormValue} />
        <div style={{ margin: '0 36px 24px 36px' }}>
          <ContentRow
            label="Your initial investment"
            value={convertNumberToString({
              value: +Utils.trim(formValue.tokenAmount * formValue.tokenPrice, 4),
              prefix: '$',
            })}
          />
          <ContentRow
            label="Current wealth"
            value={convertNumberToString({
              value: +Utils.trim(formValue.monkBalance * formValue.tokenPrice, 4),
              prefix: '$',
            })}
          />
          <ContentRow
            label="Token rewards estimation"
            value={convertNumberToString({
              value: +Utils.trim(
                getAPDValue(formValue.apy, formValue.days) * formValue.tokenAmount,
                4,
              ),
              suffix: ' Token',
            })}
          />
          <ContentRow
            label="Potential return"
            value={convertNumberToString({
              value: +Utils.trim(
                getAPDValue(formValue.apy, formValue.days) *
                  formValue.tokenAmount *
                  formValue.futureTokenPrice,
                4,
              ),
              prefix: '$',
            })}
          />
        </div>
      </ContentCard>
    </UseCaseLayout>
  );
};
export default CalculatorPage;
