import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { Button, ButtonProps } from 'antd';
import './_styles.scss';

interface IProps extends ButtonProps {
  text?: string;
  className?: string;
  style?: CSSProperties;
}

const MSOutlinedButton: React.FunctionComponent<IProps> = ({
  text,
  className,
  style,
  ...props
}) => {
  return (
    <Button className={clsx('ms-outlined-button', className)} style={style} {...props}>
      {text}
    </Button>
  );
};
export default MSOutlinedButton;
