import { gql } from 'graphql-request';
import moment from 'moment';

export const endpoint = 'https://api.studio.thegraph.com/query/19621/monastery-graph/v0.0.16';

export const dashboardDataQuery = gql`
  {
    protocolMetrics(first: 100, orderBy: timestamp, orderDirection: desc) {
      id
      timestamp
      monkCirculatingSupply
      zenCirculatingSupply
      totalSupply
      monkPrice
      marketCap
      totalValueLocked
      treasuryRiskFreeValue
      treasuryMarketValue
      nextEpochRebase
      nextDistributedMonk
      treasuryDaiRiskFreeValue
      treasuryDaiMarketValue
      currentAPY
      safeHandAPY
      furryHandAPY
      stoneHandAPY
      diamondHandAPY
      runway2dot5k
      runway5k
      runway7dot5k
      runway10k
      runway20k
      runway50k
      runway70k
      runway100k
      runwayCurrent
      treasuryMonkDaiPOL
    }
  }
`;

export const getDates = (data: any[]): string[] => {
  const firstDate = Number(data[data.length - 1].timestamp);
  const lastDate = Number(data[0].timestamp);
  const duration = lastDate - firstDate;
  const durationRate = parseInt((duration / 5).toFixed(0));
  return [
    moment((firstDate + durationRate) * 1000).format('MMM DD'),
    moment((firstDate + 2 * durationRate) * 1000).format('MMM DD'),
    moment((firstDate + 3 * durationRate) * 1000).format('MMM DD'),
    moment((firstDate + 4 * durationRate) * 1000).format('MMM DD'),
    moment((firstDate + 5 * durationRate) * 1000).format('MMM DD'),
  ];
};
