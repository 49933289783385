import React from 'react';
import HomePage from 'pages/home-page';
import DashboardPage from 'pages/dashboard';
import StakePage from 'pages/stake';
import GovernancePage from 'pages/governance';
import GovernanceIdPage from 'pages/governance-id';
import GovernanceCreatePage from 'pages/governance-create';
import CalculatorPage from 'pages/calculator';
import BondPage from 'pages/bond';
import ClaimPage from 'pages/claim';

export interface IRoute {
  path: string;
  element: React.FunctionComponent;
  isRoot?: boolean;
}

const ApplicationRoutes: Array<IRoute> = [
  {
    path: '/',
    element: HomePage,
    isRoot: true,
  },
  {
    path: '/dashboard',
    element: DashboardPage,
  },
  {
    path: '/stake',
    element: StakePage,
  },
  {
    path: '/claim',
    element: ClaimPage,
  },
  {
    path: '/bond',
    element: BondPage,
  },
  {
    path: '/calculator',
    element: CalculatorPage,
  },
  {
    path: '/governance',
    element: GovernancePage,
    isRoot: true,
  },
  {
    path: '/governance/create',
    element: GovernanceCreatePage,
  },
  {
    path: '/governance/:id',
    element: GovernanceIdPage,
  },
];
export default ApplicationRoutes;
