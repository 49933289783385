import React, { ReactElement } from 'react';
import { Divider, Dropdown, Menu } from 'antd';
import ContainedButton from 'components/global/buttons/contained-button';
import BackButton from 'components/global/back-button';
import ConnectWallet from 'components/global/connect-wallet';
import ContractAddress from 'constants/ContractAddress';
import './_styles.scss';

interface INavbarProps {
  withBack?: boolean;
}

const Navbar: React.FunctionComponent<INavbarProps> = ({ withBack }) => {
  const addToken = async (type: 'MONK' | 'ZEN') => {
    // @ts-ignore;
    const { ethereum } = window;
    if (ethereum) {
      try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: ContractAddress[type === 'MONK' ? 'MONK_ADDRESS' : 'ZEN_ADDRESS'], // The address that the token is at.
              symbol: type, // A ticker symbol or shorthand, up to 5 chars.
              decimals: 9, // The number of decimals in the token
              // image: tokenImage, // A string url of the token logo
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const sushiLink =
    'https://app.sushi.com/swap?inputCurrency=0x6b175474e89094c44da98b954eedeac495271d0f&outputCurrency=0xa4E27ea37D18bb0f483779f9E75A6024EFa5E73e';

  const menu: ReactElement = (
    <Menu className="navbar-menu">
      <Menu.Item key="1" style={{ height: '54px' }}>
        <a href={sushiLink} target="_blank" className="navbar-menu-item">
          Exchange on SushiSwap
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <Divider dashed style={{ borderColor: 'gray', margin: 0 }} />
      </Menu.Item>
      <Menu.Item key="3">
        <p className="navbar-menu-item">Add Token To Wallet</p>
      </Menu.Item>
      <Menu.Item key="4">
        <Divider dashed style={{ borderColor: 'gray', margin: 0 }} />
      </Menu.Item>
      <Menu.Item key="5">
        <p className="navbar-menu-item" onClick={() => addToken('MONK')}>
          $MONK
        </p>
      </Menu.Item>
      <Menu.Item key="6">
        <p className="navbar-menu-item" onClick={() => addToken('ZEN')}>
          $ZEN
        </p>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="navbar">
      {withBack && (
        <div className="navbar-back-icon">
          <BackButton />
        </div>
      )}
      <Dropdown overlay={menu} placement="bottomCenter">
        <ContainedButton
          text="(🙏,🙏)"
          className="hide-button"
          style={{ minWidth: 111, marginInlineEnd: 16 }}
        />
      </Dropdown>
      {/*<ContainedButton*/}
      {/*  text="Wrap"*/}
      {/*  className="hide-button"*/}
      {/*  style={{ minWidth: 95, marginInlineEnd: 16 }}*/}
      {/*/>*/}
      <ConnectWallet />
    </div>
  );
};
export default Navbar;
