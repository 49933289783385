import React from 'react';
import ContainedButton from 'components/global/buttons/contained-button';
import IconCreator from 'components/global/icon-creator';
import './_styles.scss';
import { useNavigate } from 'react-router-dom';

const LearnMore: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <section className="learn-more-section">
      <div className="learn-more-container">
        <IconCreator name="illustration-3" className="learn-more-img" />
        <div className="learn-more-right-box">
          <p className="learn-more-main-text">
            Fastest Growing DAO Spreading Wealth, Positivity & MONK
          </p>
          <p className="learn-more-sub-text">
            Our DAO is based on a community with Anti-whale governance mechanisms in place. We
            believe every investor should have equal rights. Participate in the fastest growing
            community backed DAO today. Join the Monks inside the Monastery today.
          </p>
          <ContainedButton text="Enter App" onClick={() => navigate('/dashboard')} />
        </div>
      </div>
    </section>
  );
};
export default LearnMore;
