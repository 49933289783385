import * as Types from './types';

export const initialState: Types.IContextState = {
  connectedAccount: null,
  tokenApproved: false,
  userBalance: {
    monkToken: 0,
    zenToken: 0,
    ROI: '0%',
    nextRewardYield: '0%',
    exchangeBalance: '0',
  },
  bonds: [],
  appLoading: false,
  notification: null,
  tokenPrice: 0,
};

export default function ContextReducer(
  state = initialState,
  action: Types.ContextActionTypes,
): Types.IContextState {
  switch (action.type) {
    case Types.SET_CONNECTED_ACCOUNT:
      return { ...state, connectedAccount: action.payload };
    case Types.SET_TOKEN_APPROVED:
      return { ...state, tokenApproved: action.payload };
    case Types.SET_USER_BALANCE:
      return { ...state, userBalance: action.payload };
    case Types.SET_BONDS:
      return { ...state, bonds: action.payload };
    case Types.SET_LOADING:
      return { ...state, appLoading: action.payload };
    case Types.SET_NOTIFICATION:
      return { ...state, notification: action.payload };
    case Types.SET_TOKEN_PRICE:
      return { ...state, tokenPrice: action.payload };
    default:
      return state;
  }
}
