import React, { useEffect, useState } from 'react';
import { useContextDispatch } from 'context/context';
import { setConnectedAddress, setTokenPrice, setUserBalance } from 'context/actions';
import { GetUserBalance } from 'services/BalanceService';
import { networkChainID } from 'constants/CoinsAddress';
import BigNumber from 'bignumber.js';
import axios from 'axios';
import { Spin } from 'antd';
// import { getAllBonds } from 'services/BondServices';
// import { AllowanceToken } from 'services/StakingServices';

export const AppProvider: React.FunctionComponent = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useContextDispatch();
  // @ts-ignore;
  const { ethereum } = window;

  const coreFetch = async () => {
    if (ethereum) {
      try {
        // TODO Change Chain ID
        setTimeout(async () => {
          if (new BigNumber(ethereum.chainId).toNumber() !== networkChainID.testnet) {
            dispatch(setConnectedAddress(null));
            setLoading(false);
            return;
          }
          const accounts = await ethereum.request({ method: 'eth_accounts' });
          if (accounts.length) {
            const accountAddress = accounts[0];
            dispatch(setConnectedAddress(accountAddress));
            setLoading(false);
            const userBalance = await GetUserBalance(accountAddress);
            if (userBalance) {
              dispatch(setUserBalance(userBalance));
            }
          }
          setLoading(false);
        }, 500);
      } catch (e) {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const getTokenPrice = async () => {
    axios
      .get(
        'https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0xa4e27ea37d18bb0f483779f9e75a6024efa5e73e&vs_currencies=usd',
      )
      .then(response => {
        if (response.status === 200) {
          // TODO change it to dynamic
          const price = 20;
          // const price = response.data['0xa4e27ea37d18bb0f483779f9e75a6024efa5e73e'].usd;

          dispatch(setTokenPrice(Number(price.toFixed(4))));
        }
      });
  };

  useEffect(() => {
    if (ethereum) {
      ethereum.on('accountsChanged', (accounts: string[]) => {
        if (accounts.length === 0) {
          dispatch(setConnectedAddress(null));
        }
      });
      ethereum.on('chainChanged', (chainId: string) => {
        // TODO Change Chain ID
        if (new BigNumber(chainId).toNumber() !== networkChainID.testnet) {
          dispatch(setConnectedAddress(null));
        }
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      await coreFetch();
      await getTokenPrice();
      setInterval(async () => {
        await getTokenPrice();
      }, 30 * 1000);
    })();
  }, []);

  if (loading) {
    return (
      <div className="app-loading">
        <Spin size="large" />
      </div>
    );
  }

  return <>{children}</>;
};

export default AppProvider;
