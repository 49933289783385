import React from 'react';
import useTitle from 'hooks/useTitle';
import UseCaseLayout from 'components/layouts/use-case';
// import { Row, Col } from 'antd';
// import ProposalsList from './components/proposals-list';
// import GovernanceSidebar from './components/sidebar';

const GovernancePage: React.FunctionComponent = () => {
  useTitle({ title: 'Governance' });
  return (
    <UseCaseLayout
      activeKey="5"
      /*className="governance-container"*/ className="dashboard-container"
    >
      <p className="coming-soon">COMING SOON</p>
      {/*<Row gutter={32}>*/}
      {/*  <Col xs={24} md={8} lg={6}>*/}
      {/*    <GovernanceSidebar />*/}
      {/*  </Col>*/}
      {/*  <Col xs={24} md={16} lg={18}>*/}
      {/*    <ProposalsList />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </UseCaseLayout>
  );
};
export default GovernancePage;
