import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import DiscordIcon from 'components/icons/Discord';
import TwitterIcon from 'components/icons/Twitter';
import MediumIcon from 'components/icons/Medium';
import LogoDark from 'components/icons/LogoDark';
import './_styles.scss';
import GithubIcon from 'components/icons/Github';

const Footer: React.FunctionComponent = () => {
  return (
    <footer className="footer-section">
      <Row gutter={{ md: 24 }}>
        <Col xs={24} sm={12} lg={9} className="footer-col-1">
          <LogoDark />
        </Col>
        <Col xs={24} sm={12} lg={5} className="footer-col-2">
          <div className="footer-title">Products</div>
          <Link className="footer-sub-menu" to="/bond">
            Bonds
          </Link>
          <Link className="footer-sub-menu" to="/stake">
            Stake
          </Link>
          <Link className="footer-sub-menu" to="/dashboard">
            Monastery APP
          </Link>
        </Col>
        <Col xs={24} sm={12} lg={5} className="footer-col-3">
          <div className="footer-title">Learn</div>
          {/*<div className="footer-sub-menu">Documentation</div>*/}
          <a href="https://monasterydao.medium.com/" target="_blank" className="footer-sub-menu">
            Medium
          </a>
        </Col>
        <Col xs={24} sm={12} lg={5} className="footer-col-4">
          <div className="footer-title">Join the community</div>
          <div className="social-logos">
            <a
              href="https://monasteryfinance.gitbook.io/product-docs/"
              target="_blank"
              className="landing-social-icon"
            >
              <GithubIcon />
            </a>
            <a
              href="https://monasterydao.medium.com/"
              target="_blank"
              className="landing-social-icon"
            >
              <MediumIcon />
            </a>
            <a
              href="https://twitter.com/ImmortaIMonks"
              target="_blank"
              className="landing-social-icon"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://discord.gg/immortalmonks"
              target="_blank"
              className="landing-social-icon"
            >
              <DiscordIcon />
            </a>
          </div>
        </Col>
        <Col xs={24}>
          <p className="footer-copy-right">
            Copyright © {new Date().getFullYear()} MONASTERY FINANCE. All rights reserved.
          </p>
        </Col>
      </Row>
    </footer>
  );
};
export default Footer;
