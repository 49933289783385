import React, { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import ApplicationRoutes from 'routes';

const RouterProvider: React.FunctionComponent = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  return (
    <Routes>
      {ApplicationRoutes.map(({ element: Element, ...item }) => (
        <Route key={item.path} element={<Element />} {...item} />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default RouterProvider;
