import { useEffect } from 'react';

interface UseQueryProps {
  title: string;
}

const useTitle = ({ title }: UseQueryProps): void => {
  useEffect(() => {
    document.title = `Monastery Finance | ${title}`;
    return () => {
      document.title = 'Monastery Finance';
    };
  }, [title]);
};

export default useTitle;
