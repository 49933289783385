import React from 'react';
import { Input, InputProps } from 'antd';
import clsx from 'clsx';
import './_styles.scss';

interface ITextInputProps extends InputProps {
  hasMaxAction?: boolean;
  endText?: string;
  className?: string;
  label?: string;
  onChanged?: (value: string) => void;
  onMax?: () => void;
  actionText?: string;
}

const TextInput: React.FunctionComponent<ITextInputProps> = ({
  hasMaxAction,
  endText,
  className,
  label,
  onChanged,
  onMax,
  actionText = 'MAX',
  ...props
}) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChanged) {
      onChanged(event.target.value);
    }
  };

  return (
    <div className="text-input-container">
      {label && <p className="text-input-label">{label}</p>}
      <div className="text-input-wrapper">
        {hasMaxAction ? (
          <button className="input-end-action" onClick={onMax}>
            {actionText}
          </button>
        ) : endText ? (
          <span>{endText}</span>
        ) : null}
        <Input className={clsx('text-input', className)} onChange={onChange} {...props} />
      </div>
    </div>
  );
};
export default TextInput;
