import React from 'react';

const MediumIcon: React.FunctionComponent = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.6363 0H2.36363C1.05773 0 0 1.05773 0 2.36363V23.6363C0 24.9423 1.05773 26 2.36363 26H23.6363C24.9423 26 26 24.9423 26 23.6363V2.36363C26 1.05773 24.9423 0 23.6363 0ZM21.9133 20.0908H14.9038V19.6973L16.5455 18.2532V10.01L12.5143 20.0908H11.8844L7.38637 9.93082V17.1245L9.44273 19.6973V20.0908H4.08673V19.6973L6.21282 17.1245V8.56582L4.32308 6.20337C4.32308 6.20337 4.32308 5.86182 4.32308 5.915H9.49473L13.5117 14.6048L16.9768 5.915H21.8862V6.20337L20.0908 7.71255V18.2532L21.9133 19.6973V20.0908Z"
        fill="#0E0E0E"
      />
    </svg>
  );
};
export default MediumIcon;
