import React from 'react';
import APY from 'components/global/apy';
import TVL from 'components/global/tvl';
import './_styles.scss';

const StakeMain: React.FunctionComponent = () => {
  return (
    <div className="stake-main">
      <APY />
      <TVL />
    </div>
  );
};
export default StakeMain;
