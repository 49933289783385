import React from 'react';
import FirstView from './components/first-view';
import Statistics from './components/statistics';
import Intro from './components/intro';
import LearnMore from './components/learn-more';
import HowItWorks from './components/how-it-works';
import Stake from './components/stake';
import Footer from './components/footer';
import './_styles.scss';

const HomePage: React.FunctionComponent = () => {
  return (
    <div className="monastery-home-page">
      <FirstView />
      <Statistics />
      <Intro />
      <LearnMore />
      <HowItWorks />
      <Stake />
      <Footer />
    </div>
  );
};
export default HomePage;
