import { INotification, IUserBalance } from 'models/@types';
import * as Types from './types';
import { IBond } from 'models/Bond';

export const setConnectedAddress = (newState: string | null): Types.ContextActionTypes => {
  return {
    type: Types.SET_CONNECTED_ACCOUNT,
    payload: newState,
  };
};

export const setTokenApproved = (newState: boolean): Types.ContextActionTypes => {
  return {
    type: Types.SET_TOKEN_APPROVED,
    payload: newState,
  };
};

export const setUserBalance = (newState: IUserBalance): Types.ContextActionTypes => {
  return {
    type: Types.SET_USER_BALANCE,
    payload: newState,
  };
};

export const setBonds = (newState: IBond[]): Types.ContextActionTypes => {
  return {
    type: Types.SET_BONDS,
    payload: newState,
  };
};

export const setLoading = (newState: boolean): Types.ContextActionTypes => {
  return {
    type: Types.SET_LOADING,
    payload: newState,
  };
};

export const setNotification = (newState: INotification): Types.ContextActionTypes => {
  return {
    type: Types.SET_NOTIFICATION,
    payload: newState,
  };
};

export const setTokenPrice = (newState: number): Types.ContextActionTypes => {
  return {
    type: Types.SET_TOKEN_PRICE,
    payload: newState,
  };
};
